import React, { useEffect } from 'react'
import * as Components from '../components/ComponentRoute'
import useTitle from '../hooks/useTitle'

const About = () => {
  const title = useTitle()

  useEffect(() => {
      title("About")
  }, [])
  return (
    <>
      <Components.HERO_COMPONENT name={"about"} bg={"bg-appointment"}/>
      <Components.ABOUT_COMPONENT/>
      <Components.MISSION_COMPONENT/>
    </>
  )
}

export default About

import { useEffect } from "react"
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from "../hooks/useTitle"
export default function LongTermInvestmentPage() {
  const title = useTitle()

  useEffect(() => {
      title("Long Term Investment")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Long Term Invesment"} bg={"bg-hni_cash"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
            <div className="section-title mb-4 zoomIn wow">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Long Term Investment
              </h5>
              <p>Investing for the long term in India involves strategies that focus on capital
                appreciation and wealth creation over an extended period, typically spanning several years or even decades.
                Here are some popular long-term investment options in India:</p>
              <ul type="square" className='mt-2'>
                <li>
                <b className="text-primary">Equity Mutual Funds:</b> Equity mutual funds invest in a diversified portfolio of stocks across various
                  sectors and market capitalizations. These funds offer the potential for higher returns over the long
                  term but come with higher volatility compared to debt funds. Investors can choose from large-cap,
                  mid-cap, small-cap, or multi-cap funds based on their risk tolerance and investment horizon.
                </li>
                <li> 
                <b className="text-primary">Index Funds and Exchange-Traded Funds (ETFs):</b> Index funds and ETFs track a specific stock market
                  index such as the Nifty 50 or Sensex in India. They offer diversification across a broad range of stocks
                  and have lower expense ratios compared to actively managed funds. Index funds and ETFs are suitable for
                  long-term investors seeking market returns with lower costs.
                </li>
                <li>
                <b className="text-primary">Public Provident Fund (PPF):</b> The Public Provident Fund is a government-backed savings scheme with a
                  lock-in period of 15 years. It offers tax benefits under Section 80C of the Income Tax Act and provides
                  compound interest. PPF is a popular choice for long-term savings and retirement planning due to its safety and tax advantages.
                </li>
                <li>
                <b className="text-primary">Employee Provident Fund (EPF):</b> EPF is a retirement savings scheme mandated by the government for
                  salaried employees. Both the employer and employee contribute a percentage of the salary to the EPF account,
                  which earns a fixed interest rate. The accumulated amount can be withdrawn at retirement or under certain specified conditions
                </li>
                <li>
                <b className="text-primary">National Pension System (NPS):</b> NPS is a voluntary pension scheme regulated by the Pension Fund
                  Regulatory and Development Authority (PFRDA). It is designed to provide retirement income to subscribers.
                  NPS offers a choice of investment options, including equity, corporate bonds, and government securities,
                  making it suitable for long-term retirement planning
                </li>
                <li>
                <b className="text-primary">Direct Equity Investment:</b> Investing directly in stocks of fundamentally strong companies with a long-term
                  growth outlook can be a rewarding but riskier option. Long-term investors often focus on companies with strong fundamentals,
                  competitive advantages, and consistent growth prospects. Diversification and thorough research are crucial for direct equity
                  investments.
                </li>
                <li>
                <b className="text-primary">Real Estate:</b> Real estate investments, such as buying residential or commercial properties, can be a long-term wealth-building strategy.
                  Real estate investments provide rental income and potential capital appreciation over time. However, real estate investments require
                  substantial capital, and investors should consider factors like location, market trends, and liquidity before investing.
                </li>
                <li>
                <b className="text-primary">Debt Instruments:</b> While equity investments offer growth potential, debt instruments such as government bonds,
                  corporate bonds, and fixed deposits provide stability and regular income. Long-term investors may allocate a
                  portion of their portfolio to debt instruments for diversification and capital preservation.
                  <p>When planning for long-term investments in India, consider your financial goals, risk tolerance, investment horizon,
                    and diversification across asset classes. It's advisable to consult with a financial advisor or planner to create a
                    customized investment strategy aligned with your objectives.
                  </p>
                  <p>Investing in the stock market for the long term in India can be a rewarding strategy for wealth creation and achieving
                    financial goals. Here are some key considerations and strategies for long-term investment in the Indian stock market:
                  </p>
                </li>
                <li>
                <b className="text-primary">Invest in Blue Chip Stocks:</b> Blue chip stocks are shares of well-established, financially strong companies with a track record
                  of stable earnings and dividends. These companies are leaders in their respective industries and have proven resilience over economic cycles.
                  Investing in blue chip stocks can provide stability and growth potential over the long term.
                </li>
                <li>
                <b className="text-primary">Diversify Your Portfolio:</b> Diversification is crucial for reducing risk in a long-term stock market investment strategy.
                  Spread your investments across different sectors, industries, and market capitalizations (large-cap, mid-cap, small-cap)
                  to mitigate the impact of volatility in any single stock or sector.
                </li>
                <li>
                <b className="text-primary">Focus on Fundamental Analysis:</b> When selecting stocks for long-term investment, focus on fundamental analysis rather than
                  short-term market trends. Evaluate factors such as the company's financial health, earnings growth potential, competitive
                  advantage (moat), management quality, industry prospects, and valuation metrics like price-to-earnings (P/E) ratio,
                  price-to-book (P/B) ratio, and dividend yield.
                </li>
                <li>
                <b className="text-primary">Invest Through Mutual Funds:</b> If you prefer a diversified approach without directly picking individual stocks,
                  consider investing in equity mutual funds. Choose funds with a long-term track record of consistent performance,
                  low expense ratios, and aligned investment objectives. Funds like large-cap funds,
                  multi-cap funds, or index funds can be suitable for long-term wealth creation.
                </li>
                <li>
                <b className="text-primary">Stay Invested for the Long Term:</b> Long-term investing requires patience and discipline.
                  Avoid frequent trading or reacting to short-term market fluctuations. Stay invested through market ups and downs,
                  as long-term investors can benefit from the power of compounding and the ability of quality stocks to deliver returns over time.
                </li>
                <li>
                <b className="text-primary">Rebalance Your Portfolio Regularly:</b> Periodically review and rebalance your portfolio to ensure it remains
                  aligned with your risk tolerance and financial goals. Rebalancing involves adjusting your asset allocation
                  by buying or selling assets to maintain the desired risk-return profile.
                </li>
                <li>
                <b className="text-primary">Consider Systematic Investment Plans (SIPs):</b> SIPs are a disciplined way to invest regularly in mutual funds.
                  Instead of timing the market, SIPs allow you to invest a fixed amount at regular intervals (monthly or quarterly),
                  which can average out the cost of purchase over time and benefit from market volatility.
                </li>
                <li>
                  <b className="text-primary">Monitor and Review:</b> While long-term investing is about staying invested, it's also important to monitor your investments periodically.
                  Review company performance, market trends, and economic indicators to ensure your investment thesis remains intact.
                  Make adjustments to your portfolio if there are fundamental changes in your investments' outlook.
                </li>
              </ul>
              <p><span className="text-danger">*</span>Before investing in the Indian stock market for the long term, consider your risk tolerance, investment horizon,
                financial goals, and consult with a financial advisor if needed to develop a well-rounded investment strategy.
              </p>
              <h6>service charge 60000/- for 6 month</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { useEffect } from 'react';

export default function AddScriptToBody({text}) {
    useEffect(() => {
        const noscript = document.createElement("noscript")
        noscript.innerHTML = text 
        document.body.appendChild(noscript)
        return () => {
            document.body.removeChild(noscript)
        }
    }, [])
    return;
}

import React, { useEffect, useState } from 'react'
import { OriginalUrl } from '../../helper'

const Index_option = () => {
    const [baseUrl, setBaseUrl] = useState()

    useEffect(() => {
        setBaseUrl(OriginalUrl())
    }, [])
    return (
        <>
            {/* Service Index Option */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-12">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Description Index Option  for Mahurat Investing Company</h5>
                            </div>
                            <div className="bg-light p-5">
                                <div className='d-flex justify-content-center mb-5'>
                                    <img src={baseUrl &&`${baseUrl}/img/indexpageimage.jpg`} width={500} alt="index" />
                                </div>
                                <p className=''><i>Let's delve into the intricacies of Mahurat
                                    Investing's Index Option products, exploring their features,
                                    benefits, and the distinct advantages they bring to investors.
                                </i></p>
                                <h5 className='mt-3'>Understanding Index Options</h5>
                                <p>
                                    Index Options, within the Mahurat Investing ecosystem,
                                    represent a derivative contract that grants investors
                                    the right, but not the obligation, to buy or sell an index
                                    at a predetermined price on or before the contract's expiration
                                    date. These options derive their value from the movements of an
                                    underlying market index, making them a powerful tool for investors
                                    seeking exposure to broad market trends.
                                </p>
                                <h4 className='mt-4'>Key Features of Mahurat Investing's Index Option Products</h4>
                                <ul style={{ listStyle: "circle" }}>
                                    <li>Comprehensive Range of Indices</li>
                                    <li>Flexibility in Trading Strategies</li>
                                    <li>Risk Mitigation Tools</li>
                                    <li>Real-Time Market Insights</li>
                                    <li>Leverage Opportunities</li>
                                    <li>Strategic Hedging</li>
                                    <li>Professional Support and Educational Resources</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Index Option */}
        </>
    )
}

export default Index_option;
import React from 'react'

export default function Discriptions({ title, text }) {
    return (
        <>
            <div className="section-title mb-4 zoomIn wow mt-5">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    {title}
                </h5>
            </div>
            <p className="mb-4 zoomIn wow">
                {text}
            </p>
        </>
    )
}

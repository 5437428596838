import React, { useEffect } from 'react'
import "./notfound.css"
import useTitle from '../../hooks/useTitle'

const NotFoundPage = () => {
    const title = useTitle()

    useEffect(() => {
        title("Not Found")
    }, [])
    return (
        <>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>4<span>0</span>4</h1>
                    </div>
                    <h2>the page you requested could not found</h2>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage
import { useEffect } from "react"
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from "../hooks/useTitle"
export default function BTSTPage() {
    const title = useTitle()

    useEffect(() => {
        title("BTST")
    }, [])
    return (
        <>
            <COMPONENT.HERO_COMPONENT name={"BTST AND STBT"} bg={"bg-btst"} />
            <section className="container-fluid wow fadeInUp mt-3" data-wow-delay="0.1s" >
                <div className="row">
                    <div className="col-12 p-3">
                        <h4>BTST (Buy Today Sell Tomorrow) and STBT (Sell Today Buy Tomorrow) are terms commonly used in stock trading,
                            particularly in the context of intraday trading and derivatives trading (like futures and options).
                            Here's an explanation of each:</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 p-2 mt-0 ">
                        <div className="shadow p-4 section-title mb-4 zoomIn wow overflow-hidden">
                            <h5 className="position-relative d-inline-block text-primary mt-2 me-2">
                                BTST (Buy Today Sell Tomorrow)
                            </h5>
                            <ul type="square">
                                <li>BTST refers to a trading strategy where an investor buys shares (or other securities)
                                    on one trading day (T day) and sells them on the next trading day (T+1 day).
                                </li>
                                <li>
                                    This strategy is used in the cash segment of the stock market, where investors
                                    buy shares in the cash market (delivery-based trading) and hold them overnight to sell them the next day.
                                </li>
                                <li>
                                    The BTST facility allows investors to sell shares the next day without waiting for the delivery of shares in
                                    their demat account, as it takes T+2 days for the settlement of shares in the Indian stock market.
                                </li>
                                <li><h6 className="mt-3 text-success">Service Charge 30,000/- For Three Month</h6></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 p-2">
                        <div className="shadow p-4 section-title mb-4 zoomIn wow overflow-hidden">
                            <h5 className="position-relative d-inline-block text-primary mt-2 me-2">
                                STBT (Sell Today Buy Tomorrow)
                            </h5>
                            <ul type="square">
                                <li>
                                    STBT refers to a trading strategy where an investor sells shares (or other securities)
                                    on one trading day (T day) without holding any existing positions (short sell) and buys them back on the next trading day (T+1 day).
                                </li>
                                <li>
                                    This strategy is used in intraday trading or in derivative segments like futures and options, where investors take short positions
                                    (sell first) based on expectations of a price decline in the short term.
                                </li>
                                <li>
                                    The STBT strategy is riskier than BTST as it involves short selling, which means selling shares that the investor doesn't own yet.
                                    It requires strict risk management and compliance with exchange regulations.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="shadow p-2">
                            <h6 className="text-dark">
                                Both BTST and STBT strategies can be used by traders and investors to take advantage of short-term price movements in the stock market.
                                These strategies require a good understanding of market trends, technical analysis, and risk management practices.
                                It's important to note that these strategies are more suitable for experienced traders who are familiar with the nuances of intraday
                                trading and derivatives trading. Additionally, regulatory guidelines and brokerage policies may apply to BTST and STBT transactions,
                                so investors should be aware of these factors before using these strategies.
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'
import useTitle from "../hooks/useTitle";

const ContactPage = () => {
  const form = useRef();

  const title = useTitle()

  useEffect(() => {
      title("Contact")
  }, [])

   const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_ccdzo58", "template_udwkx7g", form.current, {
        publicKey: "BrTPRjqZZuAMEwIMA",
      })
      .then(
        () => {
          Swal.fire({
            icon: "success",
            title: "Your enquiry is submitted",
            showConfirmButton: false,
            timer: 1500
          });
          form.current.reset();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row justify-content-center g-5">
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 wow slideInUp"
              data-wow-delay="0.1s"
            >
              <div className="bg-hero rounded-3 shadow h-100 p-5">
                <div className="section-title">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Contact Us
                  </h5>
                  <h1 className="display-6 mb-4">Feel Free To Contact Us</h1>
                </div>
                <div className="d-flex align-items-center mb-2">
                <i className="bi bi-person-fill fs-1 text-primary me-2" />
                  <div className="text-start">
                    <h5 className="mb-0">Name </h5>
                    <span>Vishal Borasi Proprietor Muhurat Investing - Investment Advisor</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-geo-alt fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Our Office</h5>
                    <span>13,14- Mukharji Nagar Indore 452015 M.P.</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-envelope-open fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Email Us</h5>
                    <a href="mailto:info@muhuratinvesting.com">
                      <span>info@muhuratinvesting.com</span>
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Call Us</h5>
                    <a href="tel:9131628434">
                      <span>+919584096836</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12  wow slideInUp"
              data-wow-delay="0.3s"
            >
              
              <div className="bg-hero shadow rounded-3 p-5">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row g-3 mt-4">
                    <div className="col-12">
                      <input
                        type="text"
                        name="user_name"
                        className="form-control border-0 px-4"
                        placeholder="Your Name"
                        style={{ height: 40 }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        name="user_email"
                        className="form-control border-0 px-4"
                        placeholder="Your Email"
                        style={{ height: 40 }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="Subject"
                        className="form-control border-0 px-4"
                        placeholder="subject"
                        style={{ height: 40 }}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        className="form-control border-0 px-4 py-3"
                        rows={5}
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-dark buttons w-100 py-3"
                        type="submit"
                        value="Send"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;

import { Link } from "react-router-dom"
import { OriginalUrl } from "../../helper"
import { RoutePath } from "../../RoutePath"

export default function DailyCompo() {
    const base_url = OriginalUrl()
    return (
        <>
            <section className="container-fluid wow fadeInUp" data-wow-delay="0.1s" >
                <div className="row">
                    <div className="col-12">
                        <div className="padding-5" >
                            <p className="text-center" style={{ width: "90%" }}>Our Trading helps clients run and transform their front,
                                middle and back-office trading operations. We provide buy-side,
                                sell-side and market infrastructure firms with a full-service offering,
                                including systems integration and technology consulting services,
                                to assist in delivering high performance trading and settlement
                                capabilities across all asset classes.</p>
                            <div className="row mt-5 padding-5">
                                <div className="col-lg-3 col-md-6 col-sm-6 p-2">
                                    <div className="bg-light rounded-3 shadow p-3">
                                        <img src={`${base_url}/img/dailyrecommandation/img1.jpg`} className="d-image" alt="img1" />
                                        <h3 className="text-center mt-3">Cash</h3>
                                        <div className="down-box mt-4 mb-3">
                                            <Link to={`${RoutePath.CASH}/${RoutePath.STOCK_CASH}`}>Stock Cash</Link>
                                            <i className="fa-solid fa-genderless fa-2xl"></i>
                                            <Link to={`${RoutePath.CASH}/${RoutePath.HNI_CASH}`}>HNI Cash</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 p-2">
                                    <div className="bg-light rounded-3 shadow p-3">
                                        <img src={`${base_url}/img/dailyrecommandation/img2.jpeg`} className="d-image" alt="img1" />
                                        <h3 className="text-center mt-3">Future</h3>
                                        <div className="down-box mt-4 mb-3">
                                            <Link to={`${RoutePath.FUTURE}/${RoutePath.STOCK_FUTURE}`}>Stock Future</Link>
                                            <i className="fa-solid fa-genderless fa-2xl"></i>
                                            <Link to={`${RoutePath.FUTURE}/${RoutePath.HNI_FUTURE}`}>HNI Future</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 p-2">
                                    <div className="bg-light rounded-3 shadow p-3">
                                        <img src={`${base_url}/img/dailyrecommandation/img3.webp`} className="d-image" alt="img1" />
                                        <h3 className="text-center mt-3">Option</h3>
                                        <div className="down-box mt-4 mb-3">
                                            <Link to={`${RoutePath.OPTION}/${RoutePath.STOCK_OPTION}`}>Stock Option</Link>
                                            <i className="fa-solid fa-genderless fa-2xl"></i>
                                            <Link to={`${RoutePath.OPTION}/${RoutePath.HNI_OPTION}`}>HNI Option</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 p-2">
                                    <div className="bg-light rounded-3 shadow p-3">
                                        <img src={`${base_url}/img/dailyrecommandation/img4.jpg`} className="d-image" alt="img1" />
                                        <h3 className="text-center mt-3">Index</h3>
                                        <div className="down-box mt-4 mb-3 overflow-hidden">
                                            <Link to={`${RoutePath.INDEX}/${RoutePath.INDEX_HNI_OPTION}`}>HNI Index Option</Link>
                                            <i className="fa-solid fa-genderless fa-2xl"></i>
                                            <Link to={`${RoutePath.INDEX}/${RoutePath.INDEX_OPTION1}`}>Index Option</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

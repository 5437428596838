import React, { useEffect, useState } from 'react'
import { OriginalUrl } from '../../helper'

const Equity_future = () => {
    const [baseUrl, setBaseUrl] = useState()

    useEffect(() => {
        setBaseUrl(OriginalUrl())
    }, [])
    return (
        <>
            {/* Service Equity Future */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-12">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Equity Future Product for Mahurat Investing Company</h5>
                            </div>
                            <div className="bg-light p-5">
                            <div className='d-flex justify-content-center mb-5'>
                                    <img src={baseUrl &&`${baseUrl}/img/equitypageimage.jpg`} width={500} alt="index" />
                                </div>
                                <p className=''><i><b>Mahurat Investing's Equity Future</b> its
                                    product ecosystem, is a financial instrument that
                                    allows investors to buy or sell an underlying asset
                                    at a predetermined price on a future date. This type
                                    of derivative contract provides a unique avenue for
                                    investors to speculate on market movements, manage risk,
                                    and enhance the overall diversification of their investment portfolio.
                                </i></p>
                                <p>
                                    <b>Mahurat Investing' Equity Future products</b> present a compelling opportunity
                                    for investors seeking to engage in futures trading. With a commitment to
                                    transparency, user-friendly interfaces, and a diverse range of underlying assets,
                                    Mahurat Investing stands as a trusted partner for those navigating the complexities
                                    of Equity Future investments.
                                </p>
                                <h5 className='mt-3'>Are you ready to explore the exciting world of Equity Future trading with Mahurat Investing? Join us today and take your investment journey to new heights.</h5>
                                <h4 className='mt-4'>Key Features of Mahurat Investing's Equity Future Products</h4>
                                <ul style={{listStyle:"circle"}}>
                                    <li>Diverse Range of Underlying Assets</li>
                                    <li>Advanced Risk Management Tools</li>
                                    <li>High Liquidity for Seamless Trading</li>
                                    <li>Real-Time Market Data and Analysis</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Equity Future End */}
        </>
    )
}

export default Equity_future
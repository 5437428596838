import { useEffect } from "react"
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from "../hooks/useTitle"
export default function ShortTermInvesmentPage() {
  const title = useTitle()

  useEffect(() => {
      title("Short Term Investment")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Short Term Invesment"} bg={"bg-bank"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
            <div className="section-title mb-4 zoomIn wow">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Short Term Invesment
              </h5>
              <p>
                Certainly, here are some examples of short-term investments that are popular in India:
              </p>
              <ul type="square" className='mt-2'>
                <li>
                  <b className="text-primary">Fixed Deposits (FDs):</b> Fixed deposits are a common short-term investment option
                  in India offered by banks and non-banking financial companies (NBFCs). Investors can deposit a lump sum for a
                  fixed period, ranging from a few days to a few years, and earn a predetermined interest rate. Interest rates
                  can vary based on the duration and the institution offering the FD.
                </li>
                <li>
                  <b className="text-primary"> Savings Accounts:</b> While not technically an investment, savings accounts in India
                  often offer competitive interest rates, especially with newer digital banks or small finance banks.
                  They provide liquidity and can be used for short-term parking of funds while earning some interest.
                </li>
                <li>
                  <b className="text-primary">Liquid Mutual Funds:</b>Liquid mutual funds are debt mutual funds that
                  invest in short-term money market instruments with very short maturities, such as treasury bills,
                  commercial papers, and certificates of deposit. These funds are known for their liquidity and stability
                  and can be a good option for short-term investments with slightly higher returns compared to savings accounts.
                </li>
                <li>
                  <b className="text-primary">Treasury Bills (T-Bills):</b> Indian government securities known as Treasury
                  Bills are short-term debt instruments issued by the Reserve Bank of India (RBI) on behalf of the Government of India.
                  T-Bills are available in three tenures: 91 days, 182 days, and 364 days, making them suitable for short-term investments.
                </li>
                <li>
                  <b className="text-primary">Corporate Fixed Deposits:</b>Similar to bank FDs, corporate fixed deposits are offered by companies
                  to raise funds. They often offer higher interest rates than bank FDs but come with slightly higher risk.
                  Investors should carefully research the credit rating and reputation of the company before investing.
                </li>
                <li>
                  <b className="text-primary">Post Office Time Deposit:</b> The Post Office Time Deposit (POTD) is a government-backed investment scheme offered by India Post.
                  It has various tenures ranging from 1 year to 5 years, with fixed interest rates. POTD is a safe option for short-term investments.
                </li>
              </ul>
              <p>These examples cover a range of short-term investment options available in India, each with its own features,
                risks, and potential returns. Investors should consider their financial goals, risk tolerance, and liquidity
                needs before choosing a short-term investment option.
              </p>
              <p>
                Trading Based on News and Events: Short-term traders often capitalize on news and events that can impact stock prices in the short term.
                This could include earnings reports, economic data releases, corporate announcements, or geopolitical events. Traders aim to enter and exit
                positions quickly based on the news flow and market sentiment.
              </p>
              <p>
                Short-Term Momentum Investing: Some investors focus on short-term momentum investing,
                where they look for stocks that are exhibiting strong upward momentum or positive price trends.
                This strategy involves identifying stocks with potential short-term catalysts or positive news flow that can drive prices higher.
              </p>
              <p>
                <b className="text-warning">Sector Rotation:</b> Another short-term strategy is to focus on sector rotation, where investors allocate capital
                to sectors or industries that are expected to outperform in the short term based on economic trends, market cycles, or sector-specific news.
              </p>
              <p>
                <span className="text-danger">*</span>It's crucial to note that short-term investing in the stock market requires active management, risk management strategies,
                and a disciplined approach. It's also important to stay updated with market trends, news, and economic indicators that can impact stock
                prices in the short term. Short-term stock investing is generally riskier than long-term investing, so investors should be prepared for
                potential volatility and losses. Consulting with a financial advisor or doing thorough research before engaging in short-term stock market
                investing is recommended.
              </p>
              <h6>Price 60000/- for six month</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

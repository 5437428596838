import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'

export default function InvestorCharterPage() {
    const title = useTitle()

    useEffect(() => {
        title("Investor Charter")
    }, [])
    return (
        <>
            <COMPONENT.HERO_COMPONENT name={"Investor Charter"} bg={"bg-offer"} />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
                        <h3 className='text-capitalize'>INVESTOR CHARTER IN RESPECT OF MUHURAT INVESTING - INVESTMENT ADVISOR</h3>
                        <ol type='A' className='invoster-chart'>
                            <li>
                                <b>Vision and Mission Statements for investors</b>
                                <ul>
                                    <b>Vision</b>
                                    <li>Invest with knowledge & safety.</li>
                                    <b>Mission</b>
                                    <li>Every investor should be able to invest in right investment products based on their needs,
                                        manage and monitor them to meet their goals, access reports and enjoy financial wellness.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Details of business transacted by the Research Analyst with respect to the investors</b>
                                <ul>
                                    <li>To enter into an agreement with the client providing all details including fee
                                        details,aspect of Conflict of interest disclosure and maintaining confidentiality
                                        ofinformation.
                                    </li>
                                    <li>To do a proper and unbiased risk – profiling and suitability assessment of theclient.</li>
                                    <li>To obtain registration with Know Your Client Registration Agency (KRA) andCentral Know
                                        Your Customer Registry (CKYC).
                                    </li>
                                    <li>To conduct audit annually. </li>
                                    <li>To disclose the status of complaints in its website. </li>
                                    <li>To disclose the name, proprietor name, type of registration, registration
                                        number,validity, complete address with telephone numbers and associated
                                        SEBIregional/local Office details in its website.</li>
                                    <li>To employ only qualified and certified employees.</li>
                                    <li>To deal with clients only from official number</li>
                                    <li>To maintain records of interactions, with all clients including prospective clients(prior to
                                        onboarding), where any conversation related to advice has taken place.</li>

                                </ul>
                            </li>
                            <li>
                                <b>Details of services provided to investors (No Indicative Timelines) </b>
                                <ul>
                                    <li>Onboarding of Clients
                                        <ul>
                                            <li>Sharing of agreement copy</li>
                                            <li>Completing KYC of clients</li>
                                        </ul>
                                    </li>
                                    <li>Disclosure to Clients
                                        <ul>
                                            <li>To provide full disclosure about its business, affiliations, compensation in
                                                the agreement.</li>
                                            <li>To not access client’s accounts or holdings for offering advice.</li>
                                            <li>To disclose the risk profile to the client.</li>
                                        </ul>
                                    </li>
                                    <li>To provide investment advice to the client based on the risk-profiling of the clients
                                        and suitability of the client.</li>
                                </ul>
                            </li>
                            <li><b>Details of grievance redressal mechanism and how to access it </b>
                                <ol type='I'>
                                    <li>In case of any grievance / complaint, an investor should approach the
                                        concernedInvestment Adviser and shall ensure that the grievance is resolved within 30
                                        days</li>
                                    <li>f the investor’s complaint is not redressed satisfactorily, one may lodge acomplaint with
                                        SEBI on SEBI’s 'SCORES' portal which is a centralized web basedcomplaints redressal
                                        system. SEBI takes up the complaints registered viaSCORES with the concerned
                                        intermediary for timely redressal. SCORES facilitatestracking the status of the complaint.</li>
                                    <li>With regard to physical complaints, investors may send their complaints to: Officeof
                                        Investor Assistance and Education, Securities and Exchange Board of India,SEBI Bhavan,
                                        Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E),Mumbai - 400 051. </li>
                                </ol>
                            </li>
                            <li>
                                <b>Expectations from the investors (Responsibilities of investors) </b>
                                <br />
                                <b>Do’s</b>
                                <ol type='I'>
                                    <li>Always deal with SEBI registered Investment Advisers.</li>
                                    <li>Ensure that the Investment Adviser has a valid registration certificate.</li>
                                    <li className='overflow-auto'>Check for SEBI registration number.Please refer to the list of all SEBI registered Investment
                                        Advisers which isavailable on SEBI website in the following link:
                                        <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=13">https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=13</a></li>
                                    <li>Pay only advisory fees to your Investment Adviser. Make payments of advisoryfees through
                                        banking channels only and maintain duly signed receiptsmentioning the details of your
                                        payments. </li>
                                    <li>Always ask for your risk profiling before accepting investment advice. Insist thatInvestment
                                        Adviser provides advisory strictly on the basis of your risk profiling andtake into account
                                        available investment alternatives.</li>
                                    <li>Ask all relevant questions and clear your doubts with your InvestmentAdviser before acting
                                        on advice.</li>
                                    <li> Assess the risk–return profile of the investment as well as theliquidity and safety aspects
                                        before making investments. </li>
                                    <li>Insist on getting the terms and conditions in writing duly signed and stamped.Read these
                                        terms and conditions carefully particularly regarding advisory fees,advisory plans, category
                                        of recommendations etc. before dealing with anyInvestment Adviser.
                                    </li>
                                    <li> Be vigilant in your transactions. </li>
                                    <li>Approach the appropriate authorities for redressal of your doubts / grievances. </li>
                                    <li>Inform SEBI about Investment Advisers offering assured or guaranteed returns.</li>
                                </ol>
                                <b>Don’ts</b>
                                <ol type='I'>
                                    <li>Don’t fall for stock tips offered under the pretext of investment advice. </li>
                                    <li>Do not provide funds for investment to the Investment Adviser. </li>
                                    <li>Don’t fall for the promise of indicative or exorbitant or assured returns by theInvestment
                                        Advisers. Don’t let greed overcome rational investment decisions</li>
                                    <li>Don’t fall prey to luring advertisements or market rumors.</li>
                                    <li>Avoid doing transactions only on the basis of phone calls or messages fromany Investment
                                        adviser or its representatives.</li>
                                    <li>Don’t take decisions just because of repeated messages and calls by InvestmentAdvisers</li>
                                    <li>Do not fall prey to limited period discount or other incentive, gifts, etc. offered byInvestment
                                        advisers.</li>
                                    <li>Don’t rush into making investments that do not match your risk takingappetite and
                                        investment goals.</li>
                                    <li>Don’t rush into making investments that do not match your risk takingappetite and
                                        investment goals.</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RoutePath } from "../../RoutePath";
import { OriginalUrl } from "../../helper";

const Header = () => {
  const location = useLocation();
  const [baseUrl, setBaseUrl] = useState();

  useEffect(() => {
    setBaseUrl(OriginalUrl());
  }, []);

  const isActiveLink = (path) => {
    return location.pathname === path
      ? "nav-item nav-link active"
      : "nav-item nav-link";
  };

  useEffect(() => {
    window.onclick = () => {
      let cls = document.getElementById('navbarCollapse').className
      if (cls.includes("show")) {
        document.getElementById('navbarCollapse').classList.remove('show')
      }
    }
  })

  return (
    <>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
        <Link to={RoutePath.HOME} className="navbar-brand p-0">
          <img
            src={baseUrl && `${baseUrl}/img/logo.jpg`}
            className="logo"
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <Link to={RoutePath.HOME} className={isActiveLink(RoutePath.HOME)}>
              Home
            </Link>
            <Link
              to={RoutePath.ABOUT}
              className={isActiveLink(RoutePath.ABOUT)}
            >
              About
            </Link>
            <div
              className={isActiveLink(RoutePath.SERVICES)}
              id="dropdown"
              style={{ cursor: "pointer" }}
            >
              Services <i className="fa-solid fa-caret-down"></i>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
              {/* <li className="dropdown-item dropdown2">
                  <Link className="dropdown-item" to={`${RoutePath.SERVICES}`}>Service</Link>
                </li> */}
                <li className="dropdown-item dropdown2">
                  <Link className="dropdown-item" to={`${RoutePath.SERVICES}/${RoutePath.GOAL_SETTING}`}>Goal setting</Link>
                </li>
                <li className="dropdown-item dropdown2">
                  For Trader <i className="fa-solid fa-caret-down"></i>
                  <ul className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton" style={{ top: '35%' }}>
                    <li>
                      <Link className="dropdown-item" to={`${RoutePath.SERVICES}/${RoutePath.DAILY_RECOMMENDATION}`}>Daily Recommendation</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={`${RoutePath.SERVICES}/${RoutePath.BTST}`}>BTST</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-item dropdown2">
                  For Investor <i className="fa-solid fa-caret-down"></i>
                  <ul className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton" style={{ top: '70%' }}>
                    <li>
                      <Link className="dropdown-item" to={`${RoutePath.SERVICES}/${RoutePath.SHORT_TERM}`}>Short Term Invesment</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={`${RoutePath.SERVICES}/${RoutePath.LONG_TERM}`}>Long Term Invesment</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <Link
              to={RoutePath.CONTACT}
              className={isActiveLink(RoutePath.CONTACT)}
            >
              Contact
            </Link>
            <Link
              to={RoutePath.COMPLAINTS}
              className={isActiveLink(RoutePath.COMPLAINTS)}
            >
              Complaints Status
            </Link>
            <Link
              to={RoutePath.BANK_DETAILS}
              className={isActiveLink(RoutePath.BANK_DETAILS)}
            >
              Bank Details
            </Link>
            <Link to={RoutePath.EKYC} className={isActiveLink(RoutePath.EKYC)}>
              eKYC
            </Link>
            <Link to={RoutePath.RPM} className={isActiveLink(RoutePath.RPM)}>
              RPM
            </Link>
          </div>
          <button
            type="button"
            className="btn text-dark"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            <i className="fa fa-search" />
          </button>
          <Link
            to="appointment.html"
            className="btn btn-primary buttons rounding py-2 px-4 ms-3"
          >
            Request A Call Back
          </Link>
        </div>
      </nav>
      {/* Navbar End */}
    </>
  );
};

export default Header;

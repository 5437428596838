import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useTitle from "../hooks/useTitle";

const EkycPage = () => {
  const title = useTitle()

  useEffect(() => {
      title("e-KYC")
  }, [])
  return (
    <>
      {/* Cards start */}
      <section
        className="container-fluid py-5 px-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className=" container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-12 mt-3">
              <div className="text-center rounded-3 shadow">
                <div className="card-body">
                  <img
                    src="./img/kycimg/kyc1.png"
                    className="kyc-image"
                    alt="img"
                  />
                  <h5 className="card-title mt-3">WHAT IS e-KYC ?</h5>
                  <p className="card-text">
                    KYC stands for Know Your Customer process of identifying and
                    verifying identify of customers.KYC allows us to understand
                    the customer better and manage risks prudently.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-3">
              <div className="text-center rounded-3 shadow">
                <div className="card-body">
                  <img
                    src="./img/kycimg/kyc2.jpg"
                    className="kyc-image"
                    alt="img"
                  />
                  <h5 className="card-title mt-3">WHY DO I HAVE TO DO KYC?</h5>
                  <p className="card-text">
                    As per guidelines of SEBI all customers of a Bank or wallet,
                    who wish to avail higher balance or ability to transfer
                    money need to complete KYC.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-3">
              <div className="text-center rounded-3 shadow">
                <div className="card-body">
                  <img
                    src="./img/kycimg/kyc3.jpg"
                    className="kyc-image"
                    alt="img"
                  />
                  <h5 className="card-title mt-3">WHY DOES REQUIRE KYC?</h5>
                  <p className="card-text">
                    Investelite objective of KYC is to identity theft, prevent
                    terrorist financing, money laundering and financial
                    fraud.KYC allows us to understand the customer better and
                    manage risks prudently.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cards end */}
      {/* Why KYC Needed section start */}
      <section
        className="container-fluid py-5 wow fadeInUp bg-hero"
        data-wow-delay="0.1s"
      >
        <div className=" contact-widget">
          <div className="container">
            <div className="row">
              <div className="card text-center col-lg-6 col-md-6 col-12">
                <h4 className="p-2 bg-dark text-light">ID PROOF</h4>
                <h4 className="p-2 bg-dark text-light">ADDRESS PROOF</h4>
                <h4 className="p-2 bg-dark text-light">ACTIVE E-MAIL</h4>
                <h4 className="p-2 bg-dark text-light">ACTIVE MOBILE NO.</h4>
                <h4 className="p-2 bg-dark text-light">PAN CARD</h4>
              </div>
              <div className="col-lg-6 col-md-6 col-12 text-white mt-3">
                <h3>Why Need To Do KYC?</h3>
                <h5 className="pt-2">
                  <i className="fa fa-check" /> KYC verifies and maintains the
                  record of investors.
                </h5>
                <h5 className="pt-2">
                  <i className="fa fa-check" /> Documents for proof of identify
                  the address.
                </h5>
                <h5 className="pt-2">
                  <i className="fa fa-check" /> Directed by the Security &amp;
                  Exchange Board Of India (SEBI).
                </h5>
                <h5 className="pt-2">
                  <i className="fa fa-check" /> The purpose is to prevent money
                  laundering, terrorist financing theft and so on.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why KYC Needed section end */}
      {/*----------- Forms start -----------*/}
      <section
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* Nav tabs start */}
        <ul className="nav nav-pills nav-justified">
          <li className="nav-item">
            <Link
              className="nav-link navs active"
              data-bs-toggle="tab"
              data-bs-target="#FormforIndividual"
            >
              Form For Individual
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link navs"
              data-bs-toggle="tab"
              data-bs-target="#FormforNonIndividual"
            >
              Form For Non-Individual
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link navs"
              data-bs-toggle="tab"
              data-bs-target="#DownloadKYCForm"
            >
              Download KYC Form
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link navs"
              data-bs-toggle="tab"
              data-bs-target="#UploadKYCForm"
            >
              Upload KYC Form
            </Link>
          </li>
        </ul>
        {/* Nav tabs end */}
        {/* Tab panes start */}
        <div className="tab-content mt-5">
          <div
            id="FormforIndividual"
            className="container tab-pane animated slideInLeft bg-hero rounded-3 shadow p-3 active"
          >
            <br />
            <h3 className="ms-4 mb-4">Form For Individual</h3>
            <form
              action=""
              id="kycform"
              encType="multipart/form-data"
              method="post"
              acceptCharset="utf-8"
            >
              <div className="row">
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-xl-5 mt-lg-3 mt-md-0 mt-0">
                  {/* <label for="text">Full Name form:</label> */}
                  <div className="input-container">
                    <input
                      className="form-control border-0"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-xl-5 mt-lg-3 mt-md-0 mt-5">
                  {/* <label for="text">Father/Spouse Name :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-user-friends icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="kycfather"
                      name="father_name"
                      placeholder="Father/Spouse Name"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-xl-5 mt-lg-3 mt-md-0 mt-5">
                  <div className="input-container ">
                    <label htmlFor="text" className="form-label">
                      DOB :
                    </label>
                    {/* <i class="fas fa-birthday-cake icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="kycbday"
                      name="bday"
                      placeholder="YYYY-MM-DD "
                      type="date"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-xl-5 mt-lg-5 mt-md-0 mt-5">
                  {/* <label for="text">Nationality :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-id-card-alt icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="nationality"
                      id="nationality"
                      placeholder="Nationality "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">PAN No. :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-id-card-alt icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="kycpancard"
                      name="pancard"
                      placeholder="Pan Card No. "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">Address Proof Number/Aadhaar, if any :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-address-card icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="uid"
                      id="uid"
                      placeholder="Address Proof / if any : "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5 border-0">
                  <label>Gender :</label>
                  <br />
                  <label className="radio-inline">
                    <input
                      id=""
                      name="gender"
                      defaultValue="male"
                      type="radio"
                    />
                    Male
                  </label>
                  <label className="radio-inline ml-5">
                    <input
                      id=""
                      name="gender"
                      defaultValue="female"
                      type="radio"
                    />
                    Female
                  </label>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5 border-0">
                  <label>Marital Status :</label>
                  <br />
                  <label className="radio-inline">
                    <input name="marital" defaultValue="single" type="radio" />
                    Single
                  </label>
                  <label className="radio-inline ml-5">
                    <input name="marital" defaultValue="married" type="radio" />{" "}
                    Married
                  </label>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5 border-0">
                  <label>Status :</label>
                  <br />
                  <label className="radio-inline">
                    <input
                      name="resident_status"
                      defaultValue="resident_status"
                      type="radio"
                    />
                    Resident Individual
                  </label>
                  <label className="radio-inline mx-xl-5">
                    <input
                      name="resident_status"
                      defaultValue="non-resident"
                      type="radio"
                    />
                    Non Resident
                  </label>
                  <label className="radio-inline">
                    <input
                      name="resident_status"
                      defaultValue="foreign-national"
                      type="radio"
                    />
                    Foreign National
                  </label>
                </div>
                <div className="form-group col-12 my-5">
                  {/* <label for="text">Specify the proof of Identity submitted :</label> */}
                  <textarea
                    className="form-control border-0"
                    rows={3}
                    name="id_proof"
                    id="id_proof"
                    placeholder="Specify the proof of Identity submitted "
                    style={{ background: "#fff", color: "#000" }}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="py-5 text-center col-12">
                  <h2 className="text-light border-0 bg-dark rounded-3 p-2">
                    Address Detail
                  </h2>
                </div>
                <div className="form-group col-12">
                  {/* <label for="text">Address for correspondence :</label> */}
                  <textarea
                    className="form-control border-0"
                    rows={3}
                    id="address"
                    name="address"
                    placeholder="Address for correspondence "
                    style={{ background: "#fff", color: "#000" }}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">City/town/village :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="t_city"
                      name="t_city"
                      placeholder="City/town/village "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Pin Code:</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-address-card icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="t_pin"
                      name="t_pin"
                      placeholder="Pin Code"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">State :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="t_state"
                      name="t_state"
                      placeholder="State "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 my-5">
                  {/* <label for="text">Country :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="t_country"
                      name="t_country"
                      placeholder="Country "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="py-5 col-12 text-center">
                  <h2 className="text-light bg-dark rounded-3 p-2">
                    Contact Detail
                  </h2>
                </div>
                <div className="form-group d-none">
                  {/* <label for="text">Tel.(Off.) :</label> */}
                  <input
                    className="form-control border-0"
                    id="tel_off"
                    name="tel_off"
                    placeholder="Office Contact"
                    minLength={6}
                    type="text"
                  />
                </div>
                <div className="form-group d-none">
                  {/* <label for="text">Tel.(Res.) :</label> */}
                  <input
                    className="form-control border-0"
                    name="tel_res"
                    minLength={6}
                    id="tel_res"
                    placeholder="Residential Contact "
                    type="text"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6">
                  {/* <label for="text">Mobile No. :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-mobile icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="mobile"
                      id="kycsinglemobile"
                      placeholder="Personal Contact "
                      type="number"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6">
                  {/* <label for="text"> Alternate Mobile Number :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-mobile icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="fax"
                      id=""
                      placeholder="Alternate Mobile Number"
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6">
                  {/* <label for="email">Email address:</label> */}
                  <div className="input-container">
                    {/* <i
                class="fas fa-envelope-open-text icon"
                aria-hidden="true"
              ></i> */}
                    <input
                      className="form-control border-0"
                      name="email"
                      id="kycemail"
                      placeholder="email address"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Specify the proof of address submitted for correspondence
                          address :</label> */}
                  <textarea
                    className="form-control border-0"
                    rows={3}
                    name="specify_address"
                    placeholder="Specify the proof of address submitted for correspondence address"
                    id="add_proof"
                    style={{ background: "#fff", color: "#000" }}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Address for correspondence :</label> */}
                  <textarea
                    className="form-control border-0"
                    rows={3}
                    id="c_add"
                    name="c_add"
                    placeholder="Address for correspondence"
                    style={{ background: "#fff", color: "#000" }}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">City/town/village :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="c_city"
                      id="c_city"
                      placeholder="City/town/village"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Pin Code :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-address-card icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="c_pincode"
                      id="c_pincode"
                      placeholder="Pin Code"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">State :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="c_state"
                      id="c_state"
                      placeholder="State"
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Country :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      name="c_country"
                      id="c_country"
                      placeholder="Country "
                      type="text"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Specify the proof of address submitted for registered address
                          :</label> */}
                  <textarea
                    className="form-control border-0"
                    rows={3}
                    name="c_add_prf"
                    id="add_prf2"
                    placeholder="Specify the proof of address submitted for registered address"
                    style={{ background: "#fff", color: "#000" }}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="py-5 col-12 text-center">
                  <h2 className="text-light bg-dark rounded-3 p-2">
                    Other Detail
                  </h2>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">Gross Annual Income Details :</label>
                    <select
                      name="income"
                      className="form-control border-0"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="Below Rs 1 Lac">Below Rs 1 Lac</option>
                      <option value="1-5 Lac">1-5 Lac</option>
                      <option value="5-10 Lac">5-10 Lac</option>
                      <option value="10-25 Lac ">10-25 Lac</option>
                      <option value="25 Lacs-1 crore">25 Lacs-1 crore</option>
                      <option value="More then 1 crore">
                        More then 1 crore
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">Occupation :</label>
                    <select
                      name="occupation"
                      className="form-control"
                      required=""
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="Private Sector">Private Sector</option>
                      <option value="Public Sector">Public Sector</option>
                      <option value="Government Service">
                        Government Service
                      </option>
                      <option value="Business">Business</option>
                      <option value="Professional">Professional</option>
                      <option value="Agriculturist">Agriculturist</option>
                      <option value="Retired">Retired</option>
                      <option value="Housewife">Housewife</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">Net-worth Amount :</label>
                    <input
                      className="form-control"
                      name="net_amount"
                      id=""
                      placeholder=" "
                      type="text"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">Net-worth Date :</label>
                    <input
                      className="form-control"
                      name="net_date"
                      id=""
                      placeholder=" "
                      type="text"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">
                      Please Selects, if applicable, for any of your authorized
                      signatories/ Promoters/ Partners/ Karta/ Trustees/ whole
                      time directors :
                    </label>
                    <select
                      name="authorized"
                      className="form-control"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="Politically Exposed Person (PEP)">
                        Politically Exposed Person (PEP)
                      </option>
                      <option value="  Related to a Politically Exposed Person (PEP)">
                        Related to a Politically Exposed Person (PEP)
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">Any Other Information : </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      name="other_info"
                      style={{ background: "#fff", color: "#000" }}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6">
                  <label htmlFor="text">
                    Upload Signature : (allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="signature"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id=""
                    type="file"
                    required=""
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6">
                  <label htmlFor="text">
                    Upload Photo : (allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="photo"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id=""
                    placeholder=""
                    type="file"
                    required=""
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Pan Card : (allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="pan_card_img"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id=""
                    placeholder=""
                    type="file"
                    required=""
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Id Proof:(allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="adhar_card_frant_img"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id=""
                    placeholder=""
                    type="file"
                    required=""
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Address Proof :(allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="adhar_card_back_img"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id=""
                    placeholder=""
                    type="file"
                    required=""
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  {/* <label for="text">Confirmation Code * : </label> */}
                  {/* <img
              id="Imageid"
              src="https://www.investelite.in/captcha/1703230383.6961.jpg"
              style="width: 150; height: 30; border: 0"
              alt="img "
            /> */}
                  <input
                    name="cicaptcha"
                    className="form-control border-0 mt-4"
                    placeholder="Please Enter Captcha"
                    type="text"
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label>
                    <input type="checkbox" id="agreecheckbox" /> I agree to all
                    <Link to="#" className="class-change-color">
                      terms &amp; conditions. :
                    </Link>
                  </label>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn submit buttons career_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            id="FormforNonIndividual"
            className="container bg-hero tab-pane animated p-3 slideInLeft rounded-3 shadow"
            style={{ color: "black !important" }}
          >
            <br />
            <h3 className="ms-4 mb-4">Form For Non-Individual</h3>
            <form
              method="post"
              id="MyKyc"
              action=""
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-lg-3 mt-0">
                  {/* <label for="text">Full Name test:</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-user icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      id="Kycnonname"
                      name="f_name"
                      placeholder="Full Name"
                      type="text"
                    />
                    <span className="text-danger" id="error_fn" />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-lg-3 mt-0">
                  {/* <label for="text">Date of incorporation of business : :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-calendar-day icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      id="kycdate"
                      name="date_of_in"
                      placeholder="Date of incorporation of business :yyyy/mm/dd"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-lg-3 mt-0">
                  {/* <label for="text">Place of incorporation :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="place_of_incorp"
                      id="place_of_inc"
                      placeholder="Place of incorporation  "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">Date of commencement of business :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-calendar-day icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="date_of_comm"
                      id="date_of_commenc"
                      placeholder="Date of commencement of business :yyyy/mm/dd "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">PAN No. :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-id-card-alt icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="pan"
                      id="kycnonpan"
                      placeholder="Pan Card No. "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">Status :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-id-card-alt icon" aria-hidden="true"></i> */}
                    <select
                      name="company_type"
                      id="status01"
                      className="form-control border-0 border-dark"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="">-Select-</option>
                      <option value="Private Limited Co.">
                        Private Limited Co
                      </option>
                      <option value="Public Ltd. Co.">Public Ltd. Co.</option>
                      <option value="Body Corporate">Body Corporate</option>
                      <option value="Partnership">Partnership</option>
                      <option value="Trust">Trust</option>
                      <option value="Charities">Charities</option>
                      <option value="NGOs">NGOs</option>
                      <option value="FI">FI</option>
                      <option value="FII">FII</option>
                      <option value="HUF">HUF</option>
                      <option value="AOP">AOP</option>
                      <option value="Bank">Bank</option>
                      <option value="Government Body">Government Body</option>
                      <option value="Non-Government Organization">
                        Non-Government Organization
                      </option>
                      <option value="Defense Establishment">
                        Defense Establishment
                      </option>
                      <option value="BOI">BOI</option>
                      <option value="Society">Society</option>
                      <option value="LLP">LLP</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Specify the proof of Identity submitted :</label> */}
                  <textarea
                    id="proof_add1"
                    placeholder="Specify the proof of Identity submitted"
                    className="form-control border-0 border-dark"
                    rows={3}
                    name="proof_add"
                    style={{ background: "#fff", color: "#000" }}
                    defaultValue={""}
                  />
                </div>
                <div className="py-5 text-center col-12">
                  <h2 className="text-light border-0 bg-dark rounded-3 p-2">
                    Address Detail
                  </h2>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Address for correspondence :</label> */}
                  <textarea
                    className="form-control border-0 border-dark"
                    rows={3}
                    name="t_address"
                    id="add_corres"
                    style={{ background: "#fff", color: "#000" }}
                    placeholder="Address for correspondence"
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">City/town/village :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="t_city"
                      id="city"
                      placeholder="City/town/village "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Pin Code :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-address-card icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="t_pin"
                      id="pincode"
                      placeholder="Pin Code"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">State :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="t_state"
                      id="t_state1"
                      placeholder="State "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Country :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      id="t_country1"
                      name="t_country"
                      placeholder="Country "
                      type="text"
                    />
                  </div>
                </div>
                <div className="py-5 text-center col-12">
                  <h2 className="text-light border-0 bg-dark rounded-3 p-2">
                    Contact Detail
                  </h2>
                </div>
                {/* <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown" style="display: none">
                      <div class="form-group">
                          <label for="text">Tel.(Off.) :</label>
                          <input class="form-control border-0" maxlength="13" minlength="6" name="contact_office"
                              id="contactoffice" placeholder="Office Contact " type="text" />
                      </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="display: none">
                      <div class="form-group">
                          <label for="text">Tel.(Res.) :</label>
                          <input class="form-control" maxlength="13" minlength="6" name="contact_resident"
                              id="tel1" placeholder="Residential Contact " type="text" />
                      </div>
                  </div> */}
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text">Mobile No. :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-mobile icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      maxLength={10}
                      name="contact_personal"
                      id="nonkycmobile"
                      placeholder="Mobile No."
                      type="text"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="text"> Alternate Mobile No.:</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-mobile icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="contact_fax"
                      id="faxno"
                      placeholder="Alternate Mobile No."
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  {/* <label for="email">Email address:</label> */}
                  <div className="input-container">
                    {/* <i
                class="fas fa-envelope-open-text icon"
                aria-hidden="true"
              ></i> */}
                    <input
                      placeholder="Email"
                      className="form-control border-0 border-dark"
                      id="kycnonemail"
                      name="email"
                      type="email"
                    />
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Specify the proof of address submitted for correspondence
                          address :</label> */}
                  <textarea
                    placeholder="Specify the proof of address submitted for correspondence
                      address"
                    className="form-control border-0 border-dark"
                    rows={3}
                    name="proof_add"
                    id="proof_add12"
                    style={{ background: "#fff", color: "#000" }}
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Address for correspondence :</label> */}
                  <textarea
                    placeholder="Address for correspondence"
                    className="form-control border-0 border-dark"
                    rows={3}
                    name="c_add"
                    id="c_add1"
                    style={{ background: "#fff", color: "#000" }}
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">City/town/village :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="c_city"
                      id="cc_city1"
                      placeholder="City/town/village "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Pin Code :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-address-card icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="c_pin"
                      id="c_pin1"
                      placeholder="Pin Code "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">State :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="c_state"
                      id="c_state1"
                      placeholder="State "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-3 col-lg-3 col-md-6 mt-5">
                  {/* <label for="text">Country :</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-city icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0 border-dark"
                      name="c_country"
                      id="c_country1"
                      placeholder="Country "
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Specify the proof of address submitted for registered address
                          :</label> */}
                  <textarea
                    placeholder="Specify the proof of address submitted for registered address"
                    className="form-control border-0 border-dark"
                    rows={3}
                    name="c_proof_add"
                    id="c_proof_add1"
                    style={{ background: "#fff", color: "#000" }}
                    defaultValue={""}
                  />
                </div>
                <div className="py-5 text-center col-12">
                  <h2 className="text-light border-0 bg-dark rounded-3 p-2">
                    Other Detail
                  </h2>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    {/* <label for="text">Gross Annual Income Details :</label> */}
                    <select
                      name="income"
                      id="gross"
                      className="form-control"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="Below Rs 1 Lac">
                        -Gross Annual Income-
                      </option>
                      <option value="Below Rs 1 Lac">Below Rs 1 Lac</option>
                      <option value="1-5 Lac">1-5 Lac</option>
                      <option value="5-10 Lac">5-10 Lac</option>
                      <option value="10-25 Lac ">10-25 Lac</option>
                      <option value="25 Lacs-1 crore">25 Lacs-1 crore</option>
                      <option value="More then 1 crore">
                        More then 1 crore
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    {/* <label for="text">Occupation :</label> */}
                    <select
                      name="occupation"
                      id="occupationwe"
                      className="form-control"
                      required=""
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="Private Sector">
                        -Select Occupation-
                      </option>
                      <option value="Private Sector">Private Sector</option>
                      <option value="Public Sector">Public Sector</option>
                      <option value="Government Service">
                        Government Service
                      </option>
                      <option value="Business">Business</option>
                      <option value="Professional">Professional</option>
                      <option value="Agriculturist">Agriculturist</option>
                      <option value="Retired">Retired</option>
                      <option value="Housewife">Housewife</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    {/* <label for="text">Net-worth Amount :</label> */}
                    <input
                      name="net_amount"
                      className="form-control"
                      id="netamount"
                      placeholder="Net-worth Amount"
                      type="text"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    {/* <label for="text">Net-worth Date :</label> */}
                    <input
                      name="net_date"
                      className="form-control"
                      id="net_worthdate"
                      placeholder="Net-worth Date"
                      type="text"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 wow fadeInDown"
                  style={{ display: "none" }}
                >
                  <div className="form-group">
                    <label htmlFor="text">
                      Please Selects, if applicable, for any of your authorized
                      signatories/ Promoters/ Partners/ Karta/ Trustees/ whole
                      time directors :
                    </label>
                    <select
                      name="pep"
                      id="politically"
                      className="form-control"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option>Politically Exposed Person (PEP)</option>
                      <option>
                        Related to a Politically Exposed Person (PEP)
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-12 mt-5">
                  {/* <label for="text">Any Other Information : </label> */}
                  <textarea
                    placeholder="Any Other Information"
                    className="form-control border-0 border-dark"
                    id="oth_inf"
                    rows={3}
                    name="other_info"
                    style={{ background: "#fff", color: "#000" }}
                    defaultValue={""}
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Signature : (allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="signature"
                    accept=".jpg,.jpeg, .png"
                    className="form-control border-0"
                    id="kycnonsignatureee"
                    placeholder=" "
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Photo :(allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="photo"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id="kycnonphotooo"
                    placeholder=" "
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Pan Card : (allow only jpg, png, jpeg Format)
                  </label>
                  <input
                    name="pan_card_img"
                    accept=".jpg,.jpeg, .png"
                    className="form-control border-0"
                    id="kycpancardimg"
                    placeholder=""
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Aadhar Card Id Proff:(allow only jpg, png, jpeg
                    Format)
                  </label>
                  <input
                    name="aadhar_card_frant_img"
                    accept=".jpg,.jpeg, .png"
                    className="form-control border-0"
                    id="kycAadharCardFrantImg"
                    placeholder=""
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Aadhar Card Look Address Proff :(allow only jpg, png,
                    jpeg Format)
                  </label>
                  <input
                    name="aadhar_card_back_img"
                    className="form-control border-0"
                    accept=".jpg,.jpeg, .png"
                    id="kycAadharCardBackImg"
                    placeholder=""
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-4 col-lg-4 col-md-6 mt-5">
                  <label htmlFor="text">Confirmation Code *: </label>
                  {/* <img
              id="Imageid"
              src="https://www.investelite.in/captcha/1703230383.6961.jpg"
              style="width: 150; height: 30; border: 0"
              alt=" "
            /> */}
                  <input
                    name="cicaptcha2"
                    id="nonkyc"
                    placeholder="Please Enter Captcha"
                    className="form-control border-0 border-dark mt-4"
                    type="text"
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label>
                    <input
                      required=""
                      type="checkbox"
                      id="agree212"
                      name="agree"
                    />
                    I agree to all
                    <Link to="#" className="class-change-color">
                      terms &amp; conditions. :
                    </Link>
                  </label>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn submit buttons career_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            id="DownloadKYCForm"
            className="container tab-pane animated slideInLeft"
          >
            <br />
            <div className="row g-5">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="text-center py-5 shadow rounded-3 bg-hero">
                  <h3 className="text-secondary">For Individual</h3>
                  <Link
                    to="#"
                    className="btn btn-success career_btn my-5"
                    download=""
                  >
                    CLick Here For Download
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="text-center py-5 shadow rounded-3 bg-hero">
                  <h3 className="text-secondary">For Non-Individual</h3>
                  <Link to="#" className="btn btn-success career_btn my-5">
                    CLick Here For Download
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            id="UploadKYCForm"
            className="container tab-pane animated slideInLeft shadow rounded-3 p-3 bg-hero"
          >
            <br />
            <h3 className="ms-4 mb-4">Form For eKYC</h3>
            <form
              method="post"
              id="uploadkycform"
              action=""
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-lg-3 mt-0">
                  {/* <label for="text">Full Name:</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-user icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      id="myname"
                      name="name"
                      placeholder=" Full Name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-lg-3 mt-0">
                  {/* <label for="text">Mobile No.:</label> */}
                  <div className="input-container">
                    {/* <i class="fas fa-mobile icon" aria-hidden="true"></i> */}
                    <input
                      className="form-control border-0"
                      maxLength={10}
                      id="umobile"
                      name="umobile"
                      placeholder=" Mobile No."
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  {/* <label for="text">E-mail Type:</label> */}
                  <div className="input-container">
                    {/* <i
                class="fas fa-envelope-open-text icon"
                aria-hidden="true"
              ></i> */}
                    <input
                      className="form-control border-0"
                      id="uemail"
                      name="uemail"
                      placeholder="E-mail"
                      type="email"
                    />
                  </div>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  {/* <label for="text">Form Type:</label> */}
                  <div className="input-container">
                    <select
                      id="utype"
                      name="form_type"
                      className="form-control border-0"
                      style={{ background: "#fff", color: "#000" }}
                    >
                      <option value="None-Individual">-Form Type-</option>
                      <option value="None-Individual">None-Individual</option>
                      <option value="Individual">Individual</option>
                      <option value="company">Company</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label htmlFor="text">
                    Upload Kyc Form (allow only pdf, doc, docs Format) :
                  </label>
                  <input
                    id="kycuploadfile"
                    className="form-control  border-0"
                    name="uploadmykycfile"
                    accept=".pdf,.doc, .docx."
                    type="file"
                  />
                </div>
                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-5">
                  <label htmlFor="text">Confirmation Code *: </label>
                  <img
                    id="Imageid"
                    src="#"
                    style={{ width: 150, height: 30, border: 0 }}
                    alt="img"
                  />
                  <input
                    name="uploadkyccaptcha"
                    id=""
                    className="form-control  border-0"
                    placeholder="Please Enter Captcha"
                    type="text"
                  />
                </div>
                <div className="form-group col-12 mt-5 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn submit buttons career_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Tab panes end */}
      </section>
      {/*----------- Forms end -----------*/}
    </>
  );
};

export default EkycPage;

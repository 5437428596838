import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'

export default function CompliancePage() {
  const title = useTitle()

  useEffect(() => {
      title("Compliance Page")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Compliance"} bg={"bg-offer"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
          <div className="section-title mb-4 zoomIn wow mt-5">
                <h5 className="position-relative d-inline-block text-dark text-uppercase">
                REGULATORY COMPLIANCE
                </h5>
            </div>
            <p>“Investment adviser” means any person, who for consideration,
              is engaged in the business of providing investment advice to clients or other persons or group of persons and includes any person who holds out himself as an investment adviser, by whatever name called.
              No person shall act as an investment adviser or hold itself out as an investment adviser unless he has obtained a certificate of registration from the Board under the SEBI (Investment Advisers) Regulations,
              2013. Some categories of persons are exempt in specified situations.</p>
            <p>An investment adviser shall act in a fiduciary capacity towards its clients and shall disclose all conflicts of interests as and when they arise.
              He shall act honestly, fairly and in the best interests of its clients and in the integrity of the market.
              He shall maintain an arms-length relationship between his activities as an investment adviser and other activities.
              He shall also act with due skill, care and diligence in the best interests of its clients and shall ensure that its
              advice is offered after thorough analysis and taking into account available alternatives based on risk profiling and suitability of the client.</p>
            <p>An investment adviser required to comply with general obligations and responsibilities such as general responsibility,
              disclosures to clients, maintenance of records, etc, as specified under chapter III of IA Regulations as well as the code of conduct as specified under third schedule of IA regulations</p>
            <p>MUHURAT INVESTING INVESTMENT ADVISOR Investment Advisor is a SEBI registered investment advisor with registration number <span className='text-primary'>INA000018692</span></p>
            <div className="section-title mb-4 zoomIn wow mt-5">
                <h5 className="position-relative d-inline-block text-dark text-uppercase">
                    KYC
                </h5>
            </div>
            <p>KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc),
              you need not undergo the same process again when you approach another intermediary.</p>
            <p>As per SEBI norms MUHURAT INVESTING INVESTMENT ADVISOR is required to collect a few documents from the customer as a part of the KYC process,
              failing to receive the documents may result in service going on temporary hold till we receive these documents, MUHURAT INVESTING INVESTMENT ADVISOR
              support team will contact the customer for these documents and help the customer in sending these documents. Please note these documents are required only one time,
              the customer will not need to send these when he/she renews the services.</p>
          </div>
        </div>
      </div>
    </>
  )
}


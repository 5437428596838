import { useEffect } from "react"
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from "../hooks/useTitle"
export default function DailyRecommendationPage() {
    const title = useTitle()

    useEffect(() => {
        title("Daily Recommendation")
    }, [])
    return (
        <>
            <COMPONENT.HERO_COMPONENT name={"Daily Recommendation"} bg={"hero-dailyrecommend"} />
            <COMPONENT.DAILYRECO_COMPONENT />
            {/* <section className="container-fluid wow fadeInUp mt-5" data-wow-delay="0.1s" >
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">Our Plans</h1>
                        <hr />
                        <div>

                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

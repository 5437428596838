export const Data = [
    {
        title:"Be Specific",
        text:"Clearly define your goals. Vague objectives make it difficult to create a plan and measure progress. Use the SMART criteria: Specific, Measurable, Achievable, Relevant, and Time-bound."
    },
    {
        title:"Set Measurable Goals",
        text:"Establish criteria to track progress. Quantifiable metrics provide clarity and help you stay accountable. For instance, if your goal is to lose weight, specify the number of pounds or inches you aim to lose."
    },
    {
        title:"Make Goals Achievable",
        text:" Ensure your goals are realistic and attainable. While it's essential to challenge yourself, setting unattainable goals can lead to frustration and demotivation."
    },
    {
        title:"Relevance Matters",
        text:"Align your goals with your values, priorities, and long-term aspirations. Goals that resonate with your interests and ambitions are more likely to keep you motivated."
    },
    {
        title:"Time-bound Objectives",
        text:"Set deadlines for achieving your goals. Timeframes create a sense of urgency and help prioritize tasks. Breaking down larger goals into smaller, time-bound milestones can make them more manageable."
    },
    {
        title:"Write Them Down",
        text:"Document your goals to solidify your commitment. Writing down your objectives enhances clarity and serves as a constant reminder of what you're working towards."
    },
    {
        title:"Develop an Action Plan",
        text:"Outline the steps required to achieve each goal. Breaking down objectives into actionable tasks provides a roadmap for progress. Regularly review and adjust your action plan as needed."
    },
    {
        title:"Stay Flexible",
        text:"Remain open to adapting your goals and strategies based on changing circumstances or feedback. Flexibility allows you to navigate unexpected challenges and seize new opportunities"
    },
    {
        title:"Seek Accountability",
        text:"Share your goals with a trusted friend, mentor, or coach who can provide support and hold you accountable. Regular check-ins help maintain momentum and provide opportunities for reflection"
    },
    {
        title:"Celebrate Milestones",
        text:"Acknowledge and celebrate your achievements along the way. Recognizing progress boosts morale and reinforces positive behaviors, motivating you to continue pursuing your goals"
    }
]
import React, { useEffect } from 'react'
import * as COMPONENT from "../../components/ComponentRoute"
import { OriginalUrl } from '../../helper'
import { Data } from './goalSettingData'
import useTitle from '../../hooks/useTitle'

export default function GoalSettingPage() {

    const title = useTitle()

    useEffect(() => {
        title("Goal Setting")
    }, [])
    return (
        <>
            <COMPONENT.HERO_COMPONENT name={"Goal Setting"} bg={"hero-goalsetting"} />
            <div className='container'>
                <div className='row'>
                    <b className='mb-2 text-primary'>Goal setting is a foundational aspect of personal and professional development, providing direction and motivation to achieve desired outcomes. Here's a structured approach to effective goal setting</b>
                    <div className='col-lg-6 col-md-6 col-sm-12 p-4'>
                        <div className='bg-white mt-2 rounded'>
                            <h4 className='ms-2 p-2 text-dark'>Features :</h4>
                            <ol className='mt-1 mb- ms-3 text-success'>
                                <li><h5 className='text-success'>Financial Planning</h5></li>
                                <li><h5 className='text-success'>Retirement Planning</h5></li>
                                <li><h5 className='text-success'>Wealth Management</h5></li>
                                <li><h5 className='text-success'>Tax Services</h5></li>
                                <li><h5 className='text-success'>Estate Planning</h5></li>
                                <li><h5 className='text-success'>Education Planning for your children</h5></li>
                                <li><h5 className='text-success'>Insurance Planning</h5></li>
                            </ol>
                        </div>

                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='p-4 overflow-hidden d-flex justify-content-center align-items-center'>
                            <img src={`${OriginalUrl()}/img/goal-image.jpg`} alt="goalimg" width={440} />
                        </div>
                    </div>
                    <div className='col-12 mt-2 p-3'>
                        <div className='row'>
                            {Data && Data.map((item, index) => (
                                <div className='col-sm-12 col-md-6 col-lg-6' key={index}>
                                    <COMPONENT.DISCRIPTIONS title={item.title} text={item.text} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-12 p-3'>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-4 shadow w-100'>
                                <h4 className='mb-2 text-dark text-capitalize'>
                                    These are just a few examples of investor services available to help individuals and families achieve their financial goals and navigate the complexities of investing and financial planning. Depending on your needs and preferences, you may choose to utilize one or more of these services to support your investment journey.
                                </h4>
                                <h3 className='mt-3 text-primary'>Service Charge ₹ 1,20,000/- year</h3>
                                <h5 className='mt-4'>Need To Pay In Two Parts</h5>
                                <p className='text-success'><span>₹ 60000</span> + <span>₹ 60000</span></p>
                                <hr />
                            </div>
                        </div>

                        <p className='mt-2 text-warning'><span className='text-warning'>*</span>here you will get proper goal setting investment tolls & regular reviw to achive your all finacial goals
                            explanation would be given in call.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

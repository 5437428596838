import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'
export default function DisclaimerPage() {
  const title = useTitle()

  useEffect(() => {
      title("Disclaimer")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Disclaimer"} bg={"bg-offer"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
            <div className="section-title mb-4 zoomIn wow">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Disclaimer
              </h5>
              <ul type="square" className='mt-2'>
                <li>Vishal Borasi Proprietor Muhurat Investing - Investment Advisor is a sole proprietorship
                  registered with SEBI as Individual Investment Adviser vide Registration number INA000018692,
                  valid from 18th December, 2023 till perpetual, pursuant to which it provides Investment Advisory
                  services to its clients.
                </li>
                <li>Investment in securities market is subject to market risks.
                  Read all the related documents carefully before investing.
                </li>
                <li>Registration granted by SEBI, membership of BASL and certification from NISM in
                  no way guarantee performance of the IA or provide any assurance of returns to investors.
                </li>
                <li>MUHURAT INVESTING INVESTMENT ADVISOR™ Investment Advisor is a research house which primarily does technical and fundamental analysis in Indian securities and commodity market.
                  We provide recommendations for trading in Indian stock and commodity market. We offer high quality trading tips for equity cash, F&O, Nifty, Gold, Silver, base metal, Energy
                  and agro commodities traded in MCX and NCDEX market.
                </li>
              </ul>
              <p>This document is solely for the personal information of the recipient,
                and must not be singularly used as the basis of any investment decision.
                Nothing in this document should be construed as investment or financial advice.
                Each recipient of this document should make such investigations as they deem necessary
                to arrive at an independent evaluation of an investment in the securities of the companies
                referred to in this document (including the merits and risks involved),
                and should consult their own advisors to determine the merits and risks of such an investment.
              </p>
              <p>The information in this document has been printed on the basis of publicly available information,
                internal data and other reliable sources believed to be true, but we do not represent that it is
                accurate or complete and it should not be relied on as such, as this document is for general guidance only.
                MUHURAT INVESTING INVESTMENT ADVISOR shall not be in any way responsible for any loss or damage that may
                arise to any person from any inadvertent error in the information contained in this Document.
                MUHURAT INVESTING INVESTMENT ADVISOR has not independently verified all the information contained within
                this document. Accordingly, we cannot testify, nor make any representation or warranty,
                express or implied, to the accuracy, contents or data contained within this document.
                While MUHURAT INVESTING INVESTMENT ADVISOR endeavors to update on a reasonable basis
                the information discussed in this material, there may be regulatory, compliance,
                or other reasons that prevent us from doing so.
              </p>
              <p>This document is being supplied to you solely for your information, and its contents,
                information or data may not be reproduced, redistributed or passed on, directly or indirectly.
                MUHURAT INVESTING INVESTMENT ADVISOR, nor its directors, employees or affiliates shall be
                liable for any loss or damage that may arise from or in connection with the use of this information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const StockData = {
    cash: {
        stock_cash: {
            title: "Equity Cash product designed for traders who want to trade in Cash segment of equity market and capture momentum on intraday basis by availing margin trading feature, it also helps the trader to reduce the overnight risk that arises due to the global market.",
            image: "bg-stock_cash",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk medium.png",
            monthly_price:7000,
            quarterly_price:18000,
            name:"stock cash",
        },
        hni_cash: {
            title: "An exclusive product designed for high net worth individual trader. HNI Equity Cash come up with wide ranging targets as compared to our equity cash service. It is designed for certain active traders who want to get excessive returns by trading in scripts where higher movement is expected in intraday itself.",
            image: "bg-hni_cash",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk high.png",
            monthly_price:"",
            quarterly_price:"",
            name:"hni cash",
            hole_paymet:"45000 six month"
        }
    },
    index: {
        index_future: {
            title: "Index Future is specially designed for the traders who has focus on index. This is most popular segment for traders where they take advantage of the short-term price movements of Bank Nifty and nifty. ",
            image: "bg-index_future",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk high.png",
            monthly_price:"",
            quarterly_price:"",
            name:"hni option",
            hole_paymet:"60000 six month"
        },
        index_option: {
            title: "Index options are financial derivatives based on a basket of stocks representing either a broad or a narrow band of the overall market therefor investors can easily diversify their positions by trading them.",
            image: "bg-index_option",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk medium.png",
            monthly_price:10000,
            quarterly_price:25000,
            name:"index option"
        }
    },
    Option: {
        hni_option: {
            title: "HNI Equity option come up with wide ranging targets as compared to our equity option services. It is designed for certain active traders who want to get excessive returns by trading in scripts where higher movement is expected in intraday itself.",
            image: "bg-hni_option",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk high.png",
            monthly_price:"",
            quarterly_price:"",
            name:"hni option",
            hole_paymet:"60000 six month"
        },
        stock_option: {
            title: "Equity Option Product is designed for an options trader who wants to get advantage of higher leverage and the inherent nature of options. Options trading tips are always heavily rely on their Delta, Gama, and Vega & Theta which are eventually an integral part of derivative trading. Call & Put tips are different aspect of Bullish & Bearish market which helps rise in premium price of Option",
            image: "bg-stock_option",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk medium.png",
            monthly_price:10000,
            quarterly_price:25000,
            name:"stock option"
        }
    },
    future: {
        stock_future: {
            title: "Equity Future product is designed for trade in future segment in lot of share fixed by exchange.This is purely an F&O based trading service where you can trade both side of the market and one way to hedge your investments to balance the portfolio in market fluctuation.",
            image: "bg-stock_future",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk high.png",
            monthly_price:8000,
            quarterly_price:20000,
            name:"stock future"
        },
        hni_future: {
            title: "HNI Equity Future is designed for aggressive trader of derivative segment where targets ranges are high are match up to equity future services.We urges our client to understand the risk associated with Derivative instrument before concluding to start trading in this segment",
            image: "bg-hni_future",
            What_You_Will_Get: ["1-2 Intraday cash signal with set Target & SL.",
                "Follow-up on trade signals.",
                "Global market & Economic data update.",
                "Stock in News, RBI Policy, IIP, CPI update.",
                "24/7 Customer support"],
            risk: "/img/risk high.png",
            monthly_price:"",
            quarterly_price:"",
            name:"hni future",
            hole_paymet:"50000 six months"
        }
    }
} 
import React, { useEffect, useState } from 'react'
import { OriginalUrl } from '../../helper'

const Equity_cash = () => {
    const [baseUrl, setBaseUrl] = useState()

    useEffect(() => {
        setBaseUrl(OriginalUrl())
    }, [])
    return (
        <>
            {/* Service Equity Cash */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-12">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase"> Description Equity Cash </h5>
                            </div>
                            <div className="bg-light p-5">
                                <div className='d-flex justify-content-center mb-5'>
                                    <img src={baseUrl && `${baseUrl}/img/equitypageimage.jpg`} width={500} alt="index" />
                                </div>
                                <p className=''><i>Equity cash, in the context of <b>Mahurat Investing</b>,
                                    refers to a financial product that allows investors
                                    to buy and sell shares in the stock market. This type
                                    of investment holds significance for both seasoned and
                                    novice investors as it provides a platform for direct
                                    participation in the equity market.</i></p>
                                <h4>The Role of Products in Mahurat Investing</h4>
                                <p className='mt-2'>Mahurat Investing's <b className='text-primary'>Equity cash</b> products play a pivotal
                                    role in diversifying and strengthening an investor's
                                    portfolio. These products are carefully crafted to align
                                    with the financial goals and risk tolerance of investors,
                                    ensuring a personalized and effective investment experience</p>
                                <h4>Benefits of Equity Cash for Investors</h4>
                                <p>Investors engaging in Mahurat Investing's equity cash products
                                    enjoy several advantages. High liquidity ensures quick and efficient
                                    transactions, while the potential for higher returns attracts those
                                    seeking profitable investment opportunities. Additionally, these
                                    products aid in effective risk management, enhancing the overall
                                    stability of an investment portfolio.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Equity Cash End */}
        </>
    )
}

export default Equity_cash
import React, { useEffect, useState } from 'react'
import { firstTable, secondTable, thirdTable } from './data'
import useTitle from '../../hooks/useTitle'

export default function ComplaintsPage() {
  const [firstData, setFirstData] = useState(JSON.parse(firstTable))
  const [secondData, setSecondData] = useState(JSON.parse(secondTable))
  const [thirdData, setThirdData] = useState(JSON.parse(thirdTable))
  const title = useTitle()

  useEffect(() => {
      title("Complaint Status")
  }, [])
  return (
    <div className='container mt-3'>
      <div className='row'>
        <div className='col-sm-12 p-4 shadow-lg mb-4'>
          <h4 className='text-center'>Complaint Status</h4>
          <div className='d-flex justify-content-center'>
            <ul className='complaint-info' >
              <li>Name of Organisation - VISHAL BORASI PROPRIETOR MUHURAT INVESTING - INVESTMENT ADVISOR</li>
              <li>Status - SEBI registered Investment Adviser</li>
              <li>SEBI Registration Number -   INA000018692</li>
            </ul>
          </div>
          <h5 className='text-center mt-2'>Data for the August ending -(2024)</h5>
          {/* first table start */}
          <div className='d-flex justify-content-center p-3'>
            <div className='overflow-auto position-relative'>
              <table className='table table-bordered table-striped table-hover text-center'>
                <thead>
                  <tr className='bg-success text-white'>
                    <th>Sr.No</th>
                    <th>Received from</th>
                    <th>Pending at the end of the last month</th>
                    <th>Received</th>
                    <th>Resolved*</th>
                    <th>Total Pending*</th>
                    <th>Pending Complaints {">"} 3 Months</th>
                    <th>Average Resolution time^(in days)</th>
                  </tr>
                </thead>
                <tbody>
                  {firstData && firstData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.second}</td>
                      <td>{item.third}</td>
                      <td>{item.fourth}</td>
                      <td>{item.fifth}</td>
                      <td>{item.sixth}</td>
                      <td>{item.seven}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td>Grand Total</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.second, 0)}</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.third, 0)}</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.fourth, 0)}</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.fifth, 0)}</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.sixth, 0)}</td>
                    <td>{firstData && firstData.reduce((cur, val) => cur + val.seven, 0)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <p className='p-2 text-capitalize'>^ Average Resolution time is the sum total of time taken to resolve each complaint in days, in the current month divided by total number of complaints resolved in the current month.</p>
          {/* second table start */}
          <div>
            <h5 className='text-center m-2'>Trend of monthly disposal of complaints</h5>
            <div className='d-flex justify-content-center p-3'>
              <div className='overflow-auto position-relative'>
                <table className='table table-bordered table-striped table-hover text-center mb-5'>
                  <thead>
                    <tr className='bg-success text-white'>
                      <th>Sr.No</th>
                      <th>Month</th>
                      <th>Carried forward from previous Month</th>
                      <th>Received</th>
                      <th>Resolved*</th>
                      <th>Pending*</th>
                    </tr>
                  </thead>
                  <tbody>
                    {secondData && secondData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.month}</td>
                        <td>{item.first}</td>
                        <td>{item.Received}</td>
                        <td>{item.Resolved}</td>
                        <td>{item.Panding}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>*Inclusive of complaints of previous months resolved in the current month.</p>
                <p>#Inclusive of complaints pending as on the last day of the month.</p>
              </div>
            </div>
          </div>
          {/* third table start */}
          <div>
            <h5 className='text-center m-2'>Trend of annual disposal of complaints</h5>
            <div className='d-flex justify-content-center p-3'>
              <div className='overflow-auto position-relative'>
                <table className='table table-bordered table-striped table-hover text-center mb-5'>
                  <thead>
                    <tr className='bg-success text-white'>
                      <th>Sr.No</th>
                      <th>Year</th>
                      <th>Carried forward from the previous year</th>
                      <th>Received</th>
                      <th>Resolved*</th>
                      <th>Pending*</th>
                    </tr>
                  </thead>
                  <tbody>
                    {thirdData && thirdData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.year}</td>
                        <td>{item.first}</td>
                        <td>{item.Received}</td>
                        <td>{item.Resolved}</td>
                        <td>{item.Panding}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>Grand Total</td>
                      <td>{thirdData && thirdData.reduce((cur, val) => cur + val.first, 0)}</td>
                      <td>{thirdData && thirdData.reduce((cur, val) => cur + val.Received, 0)}</td>
                      <td>{thirdData && thirdData.reduce((cur, val) => cur + val.Resolved, 0)}</td>
                      <td>{thirdData && thirdData.reduce((cur, val) => cur + val.Panding, 0)}</td>
                    </tr>
                  </tfoot>
                </table>
                <p>*Inclusive of complaints of previous years resolved in the current year.</p>
                <p>#Inclusive of complaints pending as on the last day of the year.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../../RoutePath";

const Services = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* Service Start */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5 mb-5">
            <div className="col-12">
              <div className="section-title mb-5">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Our Services
                </h5>
              </div>
              <div className="row g-5">
                {/* Services Cards start */}
                <div
                  className="service-card wow zoomIn col-md-6 col-lg-4 col-xl-4 col-sm-12"
                  data-wow-delay="0.6s"
                >
                  <div className="service-card1" onClick={() => { navigate(`${RoutePath.SERVICES}/${RoutePath.EQUITY_CASH}`) }}>
                    <h4 className="text-center">Equity Cash</h4>
                    <hr />
                    <p className="small">
                      Equity cash, in the context of Mahurat Investing, refers
                      to a financial product that allows investors to buy and
                      sell shares in the stock market.
                    </p>
                    <h4>The Role of Products</h4>
                    <p className="small">
                      Mahurat Investing's Equity cash products play a pivotal role in diversifying and strengthening an investor's portfolio.
                    </p>
                    <Link to={`${RoutePath.SERVICES}/${RoutePath.EQUITY_CASH}`} className="btn btn-primary buttons mt-3">read more..</Link>
                    <div className="go-corner">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </div>
                <div
                  className="service-card wow zoomIn col-md-6 col-lg-4 col-xl-4 col-sm-12"
                  data-wow-delay="0.6s"
                >
                  <div className="service-card1" onClick={() => { navigate(`${RoutePath.SERVICES}/${RoutePath.EQUITY_FUTURE}`) }}>
                    <h4 className="text-center">Equity Future</h4>
                    <hr />
                    <p className="small">
                      Mahurat Investing's Equity Future its product ecosystem,
                      is a financial instrument that allows investors to buy or
                      sell an underlying asset at a predetermined price on a
                      future date.
                    </p>
                    <h4>Key Features</h4>
                    <p className="small">
                      Diverse Range of Underlying Assets
                      High Liquidity for Seamless Trading
                      Real-Time Market Data and Analysis
                    </p>
                    <Link to={`${RoutePath.SERVICES}/${RoutePath.EQUITY_FUTURE}`} className="btn btn-primary buttons mt-3">read more..</Link>
                    <div className="go-corner">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </div>
                <div
                  className="service-card wow zoomIn col-md-6 col-lg-4 col-xl-4 col-sm-12"
                  data-wow-delay="0.6s"
                >
                  <div className="service-card1" onClick={() => { navigate(`${RoutePath.SERVICES}/${RoutePath.INDEX_OPTION}`) }}>
                    <h4 className="text-center">Index Option</h4>
                    <hr />
                    <p className="small">
                      represent a derivative contract that grants investors the
                      right, but not the obligation, to buy or sell an index at
                      a predetermined price on or before the contract's
                      expiration date.
                    </p>
                    <h4>Understanding Options</h4>
                    <p className="small">
                      Real-Time Market Insights Leverage Opportunities Strategic Hedging
                      Professional Support and Educational Resources
                    </p>
                    <Link to={`${RoutePath.SERVICES}/${RoutePath.INDEX_OPTION}`} className="btn btn-primary buttons mt-3">read more..</Link>
                    <div className="go-corner">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </div>
                {/* Services Cards end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
};

export default Services;

import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'
export default function PrivacyPolicyPage() {
  const title = useTitle()

  useEffect(() => {
      title("Privacy Policy")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Privacy Policy"} bg={"bg-offer"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
            <mark>Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information.</mark>
            <h5 className='mt-4'>Personal Information-</h5>
            <p>MUHURAT INVESTING INVESTMENT ADVISOR™ only collects the necessary personally identifiable information in order to respond to your requests for our services.
              Personally identifiable information includes, but is not limited to, first and last name, e-mail address, phone number, profession,
              address and any other information that itself identifies or when tied to the above information, may identify you as a specific individual.</p>
            <p>This website does not collect personally identifiable information about you except when you specifically and knowingly provide it.</p>
            <p>By registering your mobile number on MUHURAT INVESTING INVESTMENT ADVISOR™ website,you agree to be contacted by our personnel or send you SMS’s related to our services even if the contact number you have entered is on DND (Do not Disturb).
              Purpose Of Information Collection</p>
            <h5 className='mt-4'>Purpose Of Information Collection-</h5>
            <p>Collecting information about you is necessary in order for us to provide you with high quality services.
              Purposes for collecting information generally include providing products or services requested, confirming your identity,
              protecting against fraud, or dealing with matters concerning the relationship between us.
              As there is a great risk of giving your personal information and we understand your concern, so, our way of maintaining the information is such that we assure you of the points:</p>
            <p>We only use your personal information to provide you the service at your account.
              The only use we will be bringing to your information will be for providing the services to you for which you have subscribed to us for which you gave us your information.
              We do not sell, rent, exchange, transferred or give to any company or individual for any reason without your consent for the same. We do not spam you.</p>
            <p>All information regarding Payment Gateway will be safe and secured for our site.</p>
            <p>In addition to the service provided to you your information (Mobile Number, E-Mail ID etc.)
              can be brought in use for sending you newsletters, survey, contest information, or information about any new services of the Company.
              By subscribing to our services you consent to our Privacy Policy and Terms of Use.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                COOKIES
              </h5>
            </div>
            <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. MUHURAT INVESTING INVESTMENT ADVISOR™ </p>
            <p>uses cookies to help MUHURAT INVESTING INVESTMENT ADVISOR™
              identify and track visitors, their usage of MUHURAT INVESTING INVESTMENT ADVISOR™</p>
            <p>website, and their website access preferences. MUHURAT INVESTING INVESTMENT ADVISOR™ Investment Adviser visitors who do not wish to have cookies placed on their computers should set
              their browsers to refuse cookies before using MUHURAT INVESTING INVESTMENT ADVISOR™ websites, with the drawback that certain features of MUHURAT INVESTING INVESTMENT ADVISOR™ websites may not function properly without the aid of cookies.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                PRIVACY POLICY REVISION
              </h5>
            </div>
            <p>Although most changes are likely to be minor, MUHURAT INVESTING INVESTMENT ADVISOR™
              may change its Privacy Policy from time to time, and in MUHURAT INVESTING INVESTMENT ADVISOR™  sole discretion.
              MUHURAT INVESTING INVESTMENT ADVISOR™  encourages visitors to frequently check this page for any changes to its Privacy Policy.
              Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
            <span className='privacy'>By subscribing to our services you consent to our Privacy Policy and Terms of Use.</span>
          </div>
        </div>
      </div>
    </>
  )
}

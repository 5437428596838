import React from 'react'
import Marquee from "react-fast-marquee";
const BottomBar = () => {
  return (
    <>
  {/* bottom bar section start */}
  <section className="bg-dark" style={{ height: 40 }}>
    <div className="container">
      <div className="text-center pt-2">
        <h5 className="text-white description">
          <Marquee
          style={{overflow:"hidden"}}
          >
            Registration granted by SEBI, membership of BASL and certification
            from NISM in no way guarantee performance of the intermediary or
            provide any assurance of returns to investors. The securities
            quoted, if any are for illustration only and are not recommendatory.
            Investments in securities market are subject to market risks. Read
            all the related documents carefully before investing.
          </Marquee>
        </h5>
      </div>
    </div>
  </section>
  {/* bottom bar section start */}
</>

  )
}

export default BottomBar
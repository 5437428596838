import React from 'react'

const AboutSection = () => {
  return (
    <>
  {/* About Start */}
  <div className="container-fluid py-5 mt-4 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
      <div className="row g-5 justify-content-between">
        <div className="col-lg-7">
          <div className="section-title mb-4">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">
              About Us
            </h5>
            <h4 className=" mb-0">
              Welcome to Muhurat Investing Investment Advisor
            </h4>
          </div>
          <h5 className="text-body fst-italic mb-4">
            Your Trusted Partner in Financial Success!
          </h5>
          <p className="mb-4">
            At Muhurat Investing Investment Advisor, we understand that
            navigating the complex world of investments requires expertise,
            insight, and a commitment to your financial well-being. As a leading
            investment advisory firm, we take pride in offering comprehensive
            solutions tailored to meet your unique financial goals.
          </p>
          <div className="row g-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
              {/* <h5 className="mb-3">
                <i className="fa fa-check-circle text-primary me-3" />
                Award Winning
              </h5> */}
              {/* <h5 className="mb-3">
                <i className="fa fa-check-circle text-primary me-3" />
                Professional Staff
              </h5> */}
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
              <img
                src="./img/sebi-stamp.png"
                alt="sebi-stamp"
                className="sebi-stamp"
              />
            </div>
          </div>
          {/* <a href="appointment.html" class="btn btn-primary buttons py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s">Make Appointment</a> */}
        </div>
        <div className="col-lg-5 p-4 mt-0 none wow zoomIn" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded"
              data-wow-delay="0.9s"
              src="img/about.png"
              alt='about'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
</>
  )
}

export default AboutSection
import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'

export default function CancellationPage() {
  const title = useTitle()

  useEffect(() => {
      title("Cancellation Page")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Cancellation & Refund"} bg={"bg-offer"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
          <div className="section-title mb-4 zoomIn wow mt-5">
                <h5 className="position-relative d-inline-block text-dark text-uppercase">
                CANCELLATION & REFUND
                </h5>
            </div>
            <p>All sales are final. Please note that, <span className='text-primary'>MUHURAT INVESTING INVESTMENT ADVISOR™</span>
              has stopped offering Free Trial as per new SEBI circular.
              Kindly visit our track record to check our past performance.</p>
            <p>We do not offer refunds on subscriptions that have already been taken.</p>
            <p>It is the client’s Sole responsibility to Read and understand all information associated with
              the products/services and Customer support offered from <span className='text-primary'>MUHURAT INVESTING INVESTMENT ADVISOR™</span> and its Assignees.</p>
          </div>
        </div>
      </div>
    </>
  )
}

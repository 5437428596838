import React from 'react'
import { Link } from 'react-router-dom'

export default function BackToTopButton() {
    function top() {
        window.scrollTo(0, 0)
    }
    return (
        <>
            {/* Back to Top */}
            <Link onClick={top} className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up"></i></Link>
        </>
    )
}

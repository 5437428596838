import { Link } from "react-router-dom"
import * as COMPONENT from "../../components/ComponentRoute"
import useTitle from "../../hooks/useTitle"
import { useEffect } from "react"
export default function Main({ data }) {
    const title = useTitle()

    useEffect(() => {
        title(data.name)
    }, [])
    return (
        <>
            <COMPONENT.HERO_COMPONENT name={data?.name?.toUpperCase()} bg={data?.image} height={"400px"} />
            <section className="container-fluid wow fadeInUp" data-wow-delay="0.1s" >
                {/* Nav tabs start */}
                <div className="d-flex justify-content-end">
                    <ul className="nav nav-pills nav-justified d-flex nav-size justify-content-center shadow">
                        <li className="rounded-pill">
                            <Link
                                className="tabs1 active"
                                data-bs-toggle="tab"
                                data-bs-target="#product"
                            >
                                PRODUCT DETAILS
                            </Link>
                        </li>
                        <li className="rounded-pill">
                            <Link
                                className="tabs1"
                                data-bs-toggle="tab"
                                data-bs-target="#invesment"
                            >
                                INVESTMENT RULES
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* Nav tabs end */}
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div id="product" className="container animated slideInLeft rounded-3 shadow p-3 mt-5 mb-4">
                            <h4 className="p-3 text-center">Service Charges</h4>
                            <div className="d-flex flex-column justify-content-center gap-3 mt-5">
                                {data?.hole_paymet ? <button className="btn btn-info">Rs. {data?.hole_paymet}</button> : <><button className="btn btn-info">Rs. {data?.monthly_price} (Monthly)</button>
                                    <button className="btn btn-info">Rs. {data?.quarterly_price} (Quarterly)</button></>}
                            </div>
                            <div className="d-flex justify-content-center">
                                <img src={data?.risk} height={300} width={300} alt="risk" />
                            </div>
                            <h5 className="text-center">If any Queries ?</h5>
                            <div className="d-flex justify-content-center mt-4 mb-3">
                                <Link to="tel:9131628434" className="btn btn-info btn-call">Call Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <div className="tab-content mt-5">
                            <div id="product" className="container tab-pane animated slideInLeft rounded-3 shadow p-3 active" >
                                <div className="container">
                                    <h5 className="ms-4 mb-4 mt-2">{data?.title}</h5>
                                    <hr />
                                    <h4 className="text-dark p-3">What You Will Get :</h4>
                                    <ul style={{ listStyle: "none" }}>
                                        {data?.What_You_Will_Get?.map((item, index) => (
                                            <li className="p-2" key={index}><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; {item}</h5></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div id="invesment" className="container tab-pane animated p-3 slideInLeft rounded-3 shadow" style={{ color: "black !important" }}>
                                <br />
                                <div className="container">
                                    <h5 className="text-dark p-3">Trading Rules:-</h5>
                                    <ul style={{ listStyle: "none" }}>
                                        <li className="p-2"><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; Do not trade without Stop loss order ever.</h5></li>
                                        <li className="p-2"><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; Don’t take loan or borrow money from anyone and trade in stock market.</h5></li>
                                        <li className="p-2"><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; Always take risk of your spare capital only.</h5></li>
                                        <li className="p-2"><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; Don’t be emotional or greedy while trading. Always take profit home whenever you see good profit.</h5></li>
                                        <li className="p-2"><h5><i class="fa-solid fa-arrow-right fa-beat-fade fa-lg text-success"></i> &nbsp; Don’t panic in case of any reverse situation because ups and downs are part of market and hold your position with proper Stoploss.</h5></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <COMPONENT.DAILYRECO_COMPONENT />
        </>
    )
}
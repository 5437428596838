import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'
export default function DisclosurePage() {
  const title = useTitle()

  useEffect(() => {
      title("Disclosure")
  }, [])
  return (
    <>
      <COMPONENT.HERO_COMPONENT name={"Disclosure"} bg={"bg-offer"} />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                About the Investment Advisor:
              </h5>
            </div>
            <p className='mt-2'>Muhurat Investing - Investment Advisor is a Sole Proprietorship having its registered office at 13-14 Mukharji Nagar,
              Gali No 07 Panch Mori, Indore, Madhya Pradesh, 452015 Mr. Vishal Borasi is the Sole Proprietor of M/S Muhurat Investing - Investment Advisor</p>
            <p className='mt-2'>M/S Muhurat Investing got registered with SEBI as an Investment Adviser on December 18, 2023 vide registration No. INA000018692 and is valid till perpetual.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                About the Proprietor:
              </h5>
            </div>
            <p className='mt-2'>Mr. Vishal Borasi is an MBA from DR. A.P.J. Abdul Kalam University, Indore, with extensive experience in sales and financial services.
              He has held positions such as Assistant Manager-Sales at CapitalVia Global Research Limited, Deputy Sales Head at Capital Life Market Research,
              and Channel Partner of Angel Broking Private Limited (SEBI Authorized Person). Vishal possesses strong skills in sales management, market research,
              client relations, leadership, and financial services regulation. He has a successful track record and is adaptable to different roles within the industry.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                About Business activities:
              </h5>
            </div>
            <strong>Under the ambit of SEBI Registered Investment Adviser, Muhurat Investing offers below services:</strong>
            <ul className='mt-2'>
              <li>Providing financial planning and crafting personalized investment strategies, including asset allocation, diversification, and risk management.</li>
              <li>Developing detailed financial plans tailored to each client's objectives and risk tolerance.</li>
              <li>Regularly reviewing and adjusting financial plans, typically semi-annually or annually.</li>
              <li>Identifying investment opportunities by analyzing financial data and market trends, and offering recommendations.</li>
              <li>Actively monitoring and managing clients' investments to match their goals and risk tolerance.</li>
              <li>Offering continuous support and updates, addressing client queries, and staying current with industry developments.</li>
              <li>Advice will be provided on equity market and stocks, fixed deposits and similar products, saving schemes like PPF, SCSS, and NSC,
                pension and annuity schemes, mutual funds, income tax saving strategies, and overall investment advisory.</li>
              <li>Maintaining precise records of client interactions and transactions.</li>
            </ul>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Disciplinary History:
              </h5>
            </div>
            <p>There are no outstanding litigations or no disciplinary history against the Investment Advisor or Person Associated with Investment Advice.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Disclosure about conflict of interest:
              </h5>
            </div>
            <p>The Investment Advisor is not registered with AMFI, IRDA or any other SEBI Intermediaries. Hence, there is no conflict of interest.</p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Disclosure about holding position of the Investment Advisor:
              </h5>
            </div>
            <p>Person associated with Investment Advice may have a holding position in the same financial products and securities which are subject
              matter of advice given to the clients. </p>
            <div className="section-title mb-4 zoomIn wow mt-5">
              <h5 className="position-relative d-inline-block text-dark text-uppercase">
                Other Disclosure:
              </h5>
            </div>
            <p>The Investment Advisor shall provide execution services only through direct schemes /products in the market. Mr. Vishal Borasi,
              Proprietor and the person associated with the Investment Advice shall be responsible for providing implementation services. However,
              the Investment Advisor will not receive any consideration for the execution services.  </p>
            <hr />
            <b className="mb-md-0 text-dark">
              All investments in Stock Markets, Commodity Markets and other
              financial instruments traded here are subject to market risks
              and the Returns may go up or down depending upon the factors
              and forces affecting the securities market including the
              fluctuations in the interest rates.
            </b>
            <ul className='mt-2'>
              <li>
                KYC (Know Your Customer) and Risk Profiling/Risk
                Assessment need to be completed before you can use any
                services of MUHURAT INVESTING INVESTMENT ADVISOR. Even if
                the subscription fees are paid, no services would be
                rendered/activated to any customer before the completion
                of KYC (Know Your Customer) and Risk Profiling
                formalities. For KYC (Know Your Customer) formalities we
                proceed with the information mentioned on the PAN Card
                Number only.
              </li>
              <li>
                There can be no assurance that an objective will be
                achieved. The past performance of the
                calls/recommendations given or managed by MUHURAT
                INVESTING INVESTMENT ADVISOR and its affiliates is not
                necessarily indicative of the future performance of the
                Calls/Advice. The Service is not guaranteeing or assuring
                any returns under any of the Services subscribed to
                herewith.
              </li>
              <li>
                The investments made in the Stock Markets, Commodity
                Markets and/or in any of financial instruments are subject
                to external risks. The floating rate investments are
                subject to risks pertaining to Interest rate movement,
                Spread Movement, Settlement and Liquidity.
              </li>
              <li>
                MUHURAT INVESTING INVESTMENT ADVISOR will not be liable
                for any, direct or indirect, consequential or incidental
                damages or loss arising out of the use of the information
                given on the website or through SMS.
              </li>
              <li>
                MUHURAT INVESTING INVESTMENT ADVISOR generates all its
                Advice/Recommendations via the science of Technical and
                fundamental analysis. Our services are limited to
                providing research-based trading signals.
              </li>
              <li>
                MUHURAT INVESTING INVESTMENT ADVISOR is a SEBI registered
                investment adviser with the registration number
                (INA000018692).
              </li>
              <li>
                MUHURAT INVESTING INVESTMENT ADVISOR does not provide any
                execution based/PMS (Portfolio management) based services.
                We only provide investment advice to customers on a
                pre-paid subscription basis.
              </li>
              <li>
                MUHURAT INVESTING INVESTMENT ADVISOR does not have
                affiliations with any intermediary and hence does not
                receive any remuneration or compensation of any form from
                any other intermediary.
              </li>
              <li>
                Investment/trading in the securities markets is subject to
                market risk and MUHURAT INVESTING INVESTMENT ADVISOR
                doesn’t offer any assured and guaranteed returns on any
                services/products. MUHURAT INVESTING INVESTMENT ADVISOR
                doesn’t provide any profit sharing services, guaranteed
                services and services which are not mentioned in our
                website, if in case any person tries to sell such services
                please contact us on +919584096836 or mail us at
                vishuborasi@gmail.com.
              </li>
              <li>
                Registration granted by SEBI, membership of BASL, and
                certification from NISM in no way guarantee the
                performance of the intermediary or provide any assurance
                of returns to investors.
              </li>
              <li>
                Service charges must be paid only in company's bank
                account.
              </li>
            </ul>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </>
  )
}

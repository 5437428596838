export default function HeroSection({name,bg}) {
    return (
        <>
            {/* Hero Start */}
            <div className={`container-fluid py-5 ${bg} mb-5`} >
                <div className="row py-3">
                    <div className="col-12 text-center">
                        <h3 className="display-3 mt-4 text-white animated zoomIn">{name}</h3>
                    </div>
                </div>
            </div>
            {/* Hero End */}
        </>

    )
}

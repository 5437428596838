import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Topbar = () => {
  const { pathname } = useLocation()
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    if (pathname.includes('investment-advisor') || pathname.includes("Intraday-option-call") || pathname.includes("get-5-trading-stock")) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [])

  return (
    <>
      <div className="container-fluid bg-dark ps-5 pe-0 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-md-6 text-center text-lg-start d-flex mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center gap-2 text-light">
              {isShow ? <>
                <small className="py-2">
                  SEBI Registration Number: INA000018692.
                </small>
                <small className="py-2">
                  Advertisement Application No : 15835
                </small> </> : <small className="py-2">
                Investment / Trading in market is subject to market risk. SEBI
                Registration Number: INA000018692
              </small>}
            </div>
          </div>
          <div className="col-md-6 text-center text-lg-end">
            <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
              <div className="me-1 pe-1 py-2">
                <Link to="mailto:vishuborasi@gmail.com" className="toplink">
                  <p className="m-0">
                    <i className="fa fa-envelope-open me-2" />
                    info@muhuratinvesting.com
                  </p>
                </Link>
              </div>
              <div className="py-2 pe-1 ms-4">
                <Link to="tel:9131628434" className="toplink">
                  <p className="m-0">
                    <i className="fa fa-phone-alt me-2" />
                    +91 9584096836
                  </p>
                </Link>
              </div>
              <div className="py-2 pe-1 ms-4">
                <Link to="#" className="toplink">
                  <i className="fab fa-twitter fw-normal" />
                </Link>
              </div>
              <div className="py-2 pe-1 ms-4">
                <Link
                  to="https://www.facebook.com/muhuratinvesting/"
                  className="toplink"
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
              </div>
              <div className="py-2 ms-4">
                <Link
                  to="https://www.instagram.com/muhuratinvesting/"
                  className="toplink"
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Topbar
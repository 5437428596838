import React from 'react'
import * as COMPONENT from "../ComponentRoute"

const Mission = () => {
  return (
    <>
      <>
        {/* Our Mission start */}
        <div className="container-fluid py-1 fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-7">
                <COMPONENT.DISCRIPTIONS title={"Our Mission"} text={`Our mission is to empower our clients with the knowledge and
                  strategies needed to build and preserve wealth. We are dedicated to
                  providing personalized financial advice, fostering long-term
                  relationships, and delivering exceptional value through our
                  expertise and integrity.`} />
              </div>
              <div className="col-lg-5 d-flex justify-content-center">
                <img
                  className="w-50 zoomIn wow"
                  data-wow-delay="0.9s"
                  src="img/our-mission.png"
                  alt='mission'
                />
              </div>
            </div>
          </div>
        </div>
        {/* Our Mission end */}
      </>

    </>

  )
}

export default Mission
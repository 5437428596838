import React from 'react'
import { Outlet } from 'react-router'
import Topbar from '../components/topBar/Topbar';
import Header from '../components/header/Header';
import BottomBar from '../components/bottomBar/BottomBar';
import Footer from '../components/footer/Footer';

const PublicRoute = () => {
  return (
    <>
      <Topbar />
      <Header />
      <Outlet />
      <BottomBar />
      <Footer />
    </>
  )
}

export default PublicRoute;
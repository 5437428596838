import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'
import HeroSection from '../../components/heroSection/heroSection';
import { FOOTER_COMPONENT } from '../../components/ComponentRoute';
import useTitle from '../../hooks/useTitle';

export default function Get5TrandingStock() {
  const [loading, setLoading] = useState(false)
  const form = useRef();
  const title = useTitle

  useEffect(() => {
    title("Get 5 trending stock every month")
  }, [])

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs
      .sendForm("service_ccdzo58", "template_ki73bzj", form.current, {
        publicKey: "BrTPRjqZZuAMEwIMA",
      })
      .then(
        () => {
          Swal.fire({
            icon: "success",
            title: "Your enquiry is submitted",
            showConfirmButton: false,
            timer: 1500
          });
          setLoading()
          form.current.reset();
        },
        (error) => {

          Swal.fire({
            icon: "Failed",
            title: "Your enquiry is Failed" + error.message,
            showConfirmButton: false,
            timer: 1500
          });
          setLoading(false)
        }
      );
  };
  return (
    <>
      {/* navbar start */}
      <nav className="navbar navbar-expand-lg bg-white px-5 py-3 py-lg-0">
        <Link
          className="navbar-brand p-0"
          to="/"
        >
          <img
            alt="logo"
            className="logo"
            src="./img/logo.jpg"
          />
        </Link>
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler"
          data-bs-target="#navbarCollapse"
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>
      </nav >
      {/* end */}

      <HeroSection bg={"bg-get-5"} />

      {/* main section */}

      <div className="container-fluid p-0 mb-5" >
        <div className="container p-3">
          <div className="row justify-content-evenly">
            <div
              className="col-12 mt-2 wow slideInUp shadow"
              data-wow-delay="0.3s"
            >
              <div className="row justify-content-center align-items-center">
                <div
                  className="col-12 wow zoomIn"
                  data-wow-delay="0.3s"
                >
                  <form ref={form} onSubmit={sendEmail}>
                    <div
                      className="d-flex flex-column p-5 rounded-3 overflow-hidden"
                      style={{
                        backdropFilter: 'blur(10px)'
                      }}
                    >
                      <div className="section-title1 d-flex justify-content-center mb-5 ">
                        <h6 className="position-relative d-inline-block text-center text-success text-uppercase">
                          Get 5 trending stock every month
                        </h6>
                      </div>
                      <h3 className=" text-dark mb-3 text-center">
                        Get In Touch
                      </h3>
                      <div
                        className="date row mb-3"
                        data-target-input="nearest"
                        id="date"
                      >
                        <input
                          className="form-control col-lg-6 border-1"
                          id="fullname"
                          minLength="2"
                          placeholder="Full Name"
                          required
                          style={{
                            height: '40px'
                          }}
                          type="text"
                          name="user_name"
                        />
                        <input
                          className="form-control col-lg-6 mt-4 border-1"
                          id="email"
                          placeholder="Email Address"
                          required
                          style={{
                            height: '40px'
                          }}
                          type="text"
                          name="user_email"
                        />
                        <input
                          className="form-control col-lg-6 mt-4 border-1"
                          id="mobile"
                          maxLength="10"
                          minLength="10"
                          placeholder="Mobile Number"
                          required
                          style={{
                            height: '40px'
                          }}
                          type="text"
                          name="user_number"
                        />
                        <select
                          className="form-control col-lg-6 mt-4 bg-white border-1"
                          id="subject"
                          required
                          style={{
                            height: '40px'
                          }}
                          type="text"
                          name="service"
                        >
                          <option value="Stock cash">
                            Stock cash
                          </option>
                          <option value="HNI Cash">
                            HNI Cash
                          </option>
                          <option value="Stock Future">
                            Stock Future
                          </option>
                          <option value="HNI Future">
                            HNI Future
                          </option>
                          <option value="Stock Option">
                            Stock Option
                          </option>
                          <option value="HNI option">
                            HNI option
                          </option>
                          <option value="Index Future">
                            Index Future
                          </option>
                          <option value="Index Option">
                            Index Option
                          </option>
                          <option value="BTST">
                            BTST
                          </option>
                          <option value="Short term investment">
                            Short term investment
                          </option>
                          <option value="Long term investment">
                            Long term investment
                          </option>
                          <option value="Services">
                            Services
                          </option>
                        </select>
                      </div>
                      <div className="custom-control custom-checkbox pt-2">
                        <input
                          className="form-control-input"
                          defaultChecked="checked"
                          id="customCheck1"
                          name="checkbox"
                          required
                          type="checkbox"
                        />&nbsp;
                        <label
                          className="text-dark"
                          htmlFor="customCheck1"
                        >
                          I agree to all &nbsp;
                          <Link to="#" className='text-info'>
                            Terms & Conditions
                          </Link>
                        </label>
                        <br />
                        <label className="text-dark mt-2 font-weight-bold text-capitalize">
                          <span className="text-danger" style={{ fontSize: "20px" }}>*</span>
                          Investments in securities market are subject to market risks. Read all the related documents carefully before investing.
                        </label>
                        <label className="text-dark mt-2 mb-2 font-weight-bold text-capitalize">
                          <span className="text-danger" style={{ fontSize: "20px" }}>*</span>
                          Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.
                        </label>
                      </div>
                      <div className='d-flex justify-content-center'>
                        <button
                          className="btn btn-dark btn-landing"
                        >
                          {loading ? <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3 mt-5">
                  <h4 className="mb-3 text-dark">
                    Muhurat-Investing
                  </h4>
                  <h4 className="mt-4 bg-warning p-2 rounded-1">
                    SEBI Registration Number: INA000018692
                  </h4>
                  <h4 className="mt-4 bg-warning p-2 rounded-1">
                    Advertisement Application No : 15835
                  </h4>
                  <div className="mt-5">
                    <Link
                      className="btn btn-primary py-md-3 px-md-5 buttons animated slideInRight"
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                    <Link
                      className="btn btn-success ms-4 py-md-3 px-md-5 buttons1 animated slideInRight"
                      to="https://api.whatsapp.com/send?phone=9131628434"
                    >
                      whatsapp Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >

      < FOOTER_COMPONENT />

    </>
  )
}

import { useEffect } from 'react'

export default function AddScriptToHead({scriptCode}) {

    useEffect(() => {
        const script = document.createElement("script")
        script.innerHTML = scriptCode;
        document.head.appendChild(script)
        return () => {
            document.head.removeChild(script)
        }
    }, [])

    return;
}
